import * as React from "react"
const ArrowIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={10}
    height={10}
    fill="none"
    {...props}
  >
    <path
      stroke="#388173"
      strokeLinecap="round"
      strokeWidth={1.5}
      d="M.8 5H8.84M5.747 1.5 9.02 4.588a.56.56 0 0 1 0 .824L5.747 8.5"
    />
  </svg>
)
export default ArrowIcon;
