import axios from "../config/axiosInterceptor";
import { BOOKMARKS_URL } from "../utils/apiEndpoints";

export const addToBookmarksAPI = async (payload, tokenPromise) => {
  const accessToken = await tokenPromise;
  return await axios({
    url: BOOKMARKS_URL + "/create",
    method: "PUT",
    headers: { Authorization: `Bearer ${accessToken}` },
    data: payload,
  });
};

export const removeFromBookmarksAPI = async (payload, tokenPromise) => {
  const accessToken = await tokenPromise;
  return await axios({
    url: BOOKMARKS_URL + "/delete",
    method: "PUT",
    headers: { Authorization: `Bearer ${accessToken}` },
    data: payload,
  });
};

export const removeAllBookmarksAPI = async (recordCreatedBy, tokenPromise) => {
  const accessToken = await tokenPromise;
  return await axios({
    url: BOOKMARKS_URL,
    method: "DELETE",
    params: { recordCreatedBy },
    headers: { Authorization: `Bearer ${accessToken}` },
  });
};

export const fetchBookmarksAPI = async (searchTerm, tokenPromise) => {
  const { pageOffset, perPage, recordCreatedBy } = searchTerm;

  let params = {
    recordCreatedBy: recordCreatedBy,
    pageOffset: pageOffset,
    pageSize: perPage,
  };

  const accessToken = await tokenPromise;
  return await axios({
    url: BOOKMARKS_URL,
    method: "GET",
    params,
    headers: { Authorization: `Bearer ${accessToken}` },
  });
};

export const checkBookmarksExistsAPI = async (searchTerm, tokenPromise) => {
  const { recordCreatedBy, azureMerchantIds = [] } = searchTerm;

  let params = {
    recordCreatedBy: recordCreatedBy,
    azureMerchantIds: azureMerchantIds.join(","),
  };

  const accessToken = await tokenPromise;
  return await axios({
    url: BOOKMARKS_URL + "/exists",
    method: "GET",
    params,
    headers: { Authorization: `Bearer ${accessToken}` },
  });
};
