import * as React from "react";
const AvtarIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={44}
    height={44}
    fill="none"
    {...props}
  >
    <g clipPath="url(#a)">
      <rect width={44} height={44} fill="#1C2120" fillOpacity={0.05} rx={22} />
      <g clipPath="url(#b)">
        <path
          stroke="#1C2120"
          strokeLinecap="round"
          strokeOpacity={0.2}
          strokeWidth={1.5}
          d="M22 24.083a5.208 5.208 0 1 0 0-10.417 5.208 5.208 0 0 0 0 10.417Zm0 0c-4.603 0-8.334 2.798-8.334 6.25m8.333-6.25c4.603 0 8.334 2.798 8.334 6.25"
        />
      </g>
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h44v44H0z" />
      </clipPath>
      <clipPath id="b">
        <path fill="#fff" d="M12 12h20v20H12z" />
      </clipPath>
    </defs>
  </svg>
);
export default AvtarIcon;
