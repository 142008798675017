import axios from "axios";
import { toast } from "react-toastify";
import { toastConfig } from "../constants/globalConstants";

const axiosInstance = axios.create({baseURL: `${process.env.REACT_APP_AZURE_API_BASE_URL}`});

// Request time
// axiosInstance.interceptors.request.use(
//   async function (config) {
//     // Do something before request is sent - can set token here
//     return config;
//   },
//   function (error) {
//     // Do something with request error
//     return Promise.reject(error);
//   }
// );

// Response time
axiosInstance.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    console.log("error interceptor ===>", error);
    let networkErrorMessage = '';
    switch (error?.response?.status) {
        case 401:
            networkErrorMessage = "Unauthorized !"
            break;
        case 403:
            networkErrorMessage = "Something went wrong !"
            break;
        case 404:
            networkErrorMessage = "Not found !"
            break;
        case 400:
            networkErrorMessage = "Bad Request !"
            break;
        case 500:
            networkErrorMessage = "Something went wrong !"
            break;
    
        default:
            networkErrorMessage = "Something went wrong !"
            break;
    }
    toast.error(networkErrorMessage, toastConfig);
    return Promise.reject(error);
  }
);

export default axiosInstance;
