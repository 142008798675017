import { hideLoading, showLoading } from "../../components/Loading/action";
import { fetchOffersAPI } from "../../services/offerServices";

export const SET_OFFERS_DATA = "SET_OFFERS_DATA";
export const SET_MERCHANTS_OFFERS_DATA = "SET_MERCHANTS_OFFERS_DATA";
export const SET_OFFERS_FILTERS = "SET_OFFERS_FILTERS";
export const SET_MERCHANT_OFFERS_FILTERS = "SET_MERCHANT_OFFERS_FILTERS";

export const fetchOffersList =
  (searchTerm, tokenPromise) => async (dispatch, getState) => {
    const { offersFilters: filterKeys } = getState()["offerReducer"];
    const { perPage = 10, skip = 0, searchValue } = searchTerm;
    const searchString = searchValue?.replace(/\s+/g, " ").trim().split(" ").join(" | ") || "";

    dispatch(showLoading());
    try {
      const response = await fetchOffersAPI(
        { searchValue: searchString, filterKeys, perPage, skip },
        tokenPromise
      );
      dispatch({ type: SET_OFFERS_DATA, payload: response.data });
      return response.data;
    } catch (error) {
      console.info("error fetchOfferList =>", error);
      return error;
    } finally {
      dispatch(hideLoading());
    }
  };

export const fetchMerchantsOffersList =
  (searchTerm, tokenPromise) => async (dispatch, getState) => {
    const { merchantOffersFilters: filterKeys } = getState()["offerReducer"];
    const { perPage = 10, skip = 0, searchValue, merchantId } = searchTerm;
    const searchString = searchValue?.replace(/\s+/g, " ").trim().split(" ").join(" | ") || "";

    dispatch(showLoading());
    try {
      const response = await fetchOffersAPI(
        { searchValue: searchString, filterKeys, perPage, skip, merchantId },
        tokenPromise
      );
      dispatch({ type: SET_MERCHANTS_OFFERS_DATA, payload: response.data });
      return response.data;
    } catch (error) {
      console.info("error fetchMerchantsOffersList =>", error);
      return error;
    } finally {
      dispatch(hideLoading());
    }
  };
