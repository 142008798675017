import axios from 'axios';
import { LOAD_MORE_SEARCH_RESULT,  } from '../../../components/Search/actions';
import { LOAD_MORE_FILTERED_RESULT } from '../../../components/SearchFilters/action';

export const LOAD_MORE_COMBINE_RESULT = "LOAD_MORE_COMBINE_RESULT"; 
export const LOAD_MORE_COMBINE_FILTERED_RESULT = "LOAD_MORE_COMBINE_FILTERED_RESULT "; 

export const LOAD_MORE_COMBINE_FILTERED_RESULT_SELECTED_ID = 'LOAD_MORE_COMBINE_FILTERED_RESULT_SELECTED_ID';
export const LOAD_MORE_COMBINE_RESULT_SELECTED_ID = 'LOAD_MORE_COMBINE_RESULT_SELECTED_ID';

export const loadMoreSearchResults = (API_URL, tokenPromise, filterApplied) => function (dispatch) {
    tokenPromise.then((accessToken) => {
        const axiosConfig = {
            method: 'GET',
            url: API_URL,
            headers: {
                'Authorization': `Bearer ${accessToken}`
            },
        };

        axios(axiosConfig)
            .then((response) => {
                if (!filterApplied) {
                    dispatch({
                        type: LOAD_MORE_SEARCH_RESULT,
                        payload: response
                    });
                }
                dispatch({
                    type: LOAD_MORE_FILTERED_RESULT,
                    payload: response
                });
            })
            .catch((error) => console.log("error = ", error));
    });
};

export const loadMoreSearchResultsDelegated = (delegatedEmailIdMap, tokenPromise, filterApplied) =>
	async function (dispatch) {
		try {
			const accessToken = await tokenPromise;
			const loadedMoreDelagatedMap = {};
			const keys = Object.keys(delegatedEmailIdMap);
			for (let key of keys) {
				const data = delegatedEmailIdMap[key];
				const API_URL = data["@odata.nextLink"];
				if (!API_URL) {
					continue;
				}
				const axiosConfig = {
					method: "GET",
					url: API_URL,
					headers: {
						Authorization: `Bearer ${accessToken}`,
					},
				};
				const response = await axios(axiosConfig);
				loadedMoreDelagatedMap[key] = response.data;
			}
			if (Object.keys(loadedMoreDelagatedMap).length > 0) {
				if (!filterApplied) {
					dispatch({
						type: LOAD_MORE_COMBINE_RESULT,
						payload: loadedMoreDelagatedMap,
					});
				}
				dispatch({
					type: LOAD_MORE_COMBINE_FILTERED_RESULT,
					payload: loadedMoreDelagatedMap,
				});
			}
		} catch (error) {
			console.log("error = ", error);
		}
	};

export const loadMoreSearchResultsDelegatedForSelectedId = (
	delegatedEmailIdMap,
    selectedEmail,
	tokenPromise,
	filterApplied
) =>
	async function (dispatch) {
		try {
			const accessToken = await tokenPromise;
			const loadedMoreDelagatedMap = { ...delegatedEmailIdMap };
			const data = delegatedEmailIdMap[selectedEmail];
			const API_URL = data["@odata.nextLink"];
			if (!API_URL) {
				return;
			}
			const axiosConfig = {
				method: "GET",
				url: API_URL,
				headers: {
					Authorization: `Bearer ${accessToken}`,
				},
			};
			const response = await axios(axiosConfig);
            loadedMoreDelagatedMap[selectedEmail] = {...response.data, value: [...data.value, ...response.data.value]};
			if (Object.keys(loadedMoreDelagatedMap).length > 0) {
				if (!filterApplied) {
					dispatch({
						type: LOAD_MORE_COMBINE_RESULT_SELECTED_ID,
						payload: loadedMoreDelagatedMap,
					});
				}
				dispatch({
					type: LOAD_MORE_COMBINE_FILTERED_RESULT_SELECTED_ID,
					payload: loadedMoreDelagatedMap,
				});
			}
		} catch (error) {
			console.log("error = ", error);
		}
	};