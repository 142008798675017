import * as React from "react"
const SettingsIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={56}
    height={56}
    fill="none"
    {...props}
  >
    <rect width={56} height={56} fill="#1C2120" rx={28} />
    <path
      fill="#FDFDFD"
      fillRule="evenodd"
      d="M24 19.758A2.758 2.758 0 0 1 26.76 17h2.483a2.758 2.758 0 0 1 2.759 2.758c0 .584.631.949 1.137.657a2.758 2.758 0 0 1 3.768 1.01l1.242 2.15a2.758 2.758 0 0 1-1.01 3.768.758.758 0 0 0 0 1.314 2.758 2.758 0 0 1 1.01 3.768l-1.242 2.15a2.758 2.758 0 0 1-3.768 1.01.758.758 0 0 0-1.137.657A2.758 2.758 0 0 1 29.242 39H26.76A2.758 2.758 0 0 1 24 36.242a.758.758 0 0 0-1.137-.657 2.758 2.758 0 0 1-3.768-1.01l-1.242-2.15a2.758 2.758 0 0 1 1.01-3.768.758.758 0 0 0 0-1.314 2.758 2.758 0 0 1-1.01-3.768l1.242-2.15a2.758 2.758 0 0 1 3.768-1.01.758.758 0 0 0 1.137-.657ZM26.76 19a.758.758 0 0 0-.759.758c0 2.124-2.298 3.45-4.137 2.389a.758.758 0 0 0-1.036.278l-1.242 2.15a.758.758 0 0 0 .278 1.036c1.839 1.062 1.839 3.716 0 4.778a.758.758 0 0 0-.277 1.036l1.241 2.15a.758.758 0 0 0 1.036.278C23.702 32.79 26 34.118 26 36.242c0 .419.34.758.759.758h2.483c.419 0 .758-.34.758-.758 0-2.124 2.3-3.45 4.138-2.39a.758.758 0 0 0 1.036-.277l1.242-2.15a.758.758 0 0 0-.278-1.036c-1.839-1.062-1.839-3.716 0-4.778a.758.758 0 0 0 .278-1.036l-1.242-2.15a.758.758 0 0 0-1.036-.278c-1.839 1.062-4.137-.265-4.137-2.389a.758.758 0 0 0-.759-.758H26.76ZM28 25a3 3 0 1 0 0 6 3 3 0 0 0 0-6Zm-5 3a5 5 0 1 1 10 0 5 5 0 0 1-10 0Z"
      clipRule="evenodd"
    />
  </svg>
)
export default SettingsIcon;
