import React from "react";
import { Route, Routes } from "react-router-dom";
import PrivateRoutes from "./PrivateRoutes";
import Layout from "../Layouts/Layout";
import useAllowedRoles from "../customHooks/useAllowedRoles/useAllowedRoles";
import { OFF_SHORE_ADMIN, SALS_REP, SUPER_ADMIN } from "../utils/constant";

const MainSearch = React.lazy(() => import("../pages/MainSearch"));
const Bookmark = React.lazy(() => import("../pages/Bookmark"));
const SearchResult = React.lazy(() => import("../pages/SearchResult"));
const MerchantSearch = React.lazy(() => import("../pages/MerchantSearch"));
const Login = React.lazy(() => import("../pages/Login"));
const MerchantsList = React.lazy(() => import("../pages/SearchResult/MerchantsList"));
const Emails = React.lazy(() => import("../pages/SearchResult/Emails"));
const LeadsList = React.lazy(() => import("../pages/SearchResult/LeadsList"));
const OpportunitiesList = React.lazy(() => import("../pages/SearchResult/OpportunitiesList"));
const DocumentsList = React.lazy(() => import("../pages/SearchResult/DocumentsList"));
const PageNotFound = React.lazy(() => import("../pages/PageNotFound"));
const MerchantLeads = React.lazy(() => import("../pages/MerchantSearch/MerchantLeads"));
const OffersList = React.lazy(() => import("../pages/SearchResult/OffersList"));
const MerchantOffers = React.lazy(() => import("../pages/MerchantSearch/MerchantOffers"));
const FundedDealsList = React.lazy(() => import("../pages/SearchResult/FundedDealsList"));
const MerchantFundedDeals = React.lazy(() => import("../pages/MerchantSearch/MerchantFundedDeals"));
const MerchantOpportunities = React.lazy(() => import("../pages/MerchantSearch/MerchantOpportunities"));
const RoleSelector = React.lazy(() => import("../pages/RoleSelector"));

const Router = () => {
  const roleBasedDocumentAccess = useAllowedRoles([SUPER_ADMIN, SALS_REP, OFF_SHORE_ADMIN]);
  
  return (
    <Routes>
      <Route element={<Layout />}>
        <Route element={<PrivateRoutes />}>
          <Route path="/roles" exact element={<RoleSelector />} />
          <Route path="/main" exact element={<MainSearch />} />
          <Route path="/bookmark" exact element={<Bookmark />} />
          <Route path="/search/" element={<SearchResult />}>
            <Route exact index path="merchants" element={<MerchantsList />} />
            {roleBasedDocumentAccess && <Route exact path="documents" element={<DocumentsList />} />}
            <Route exact path="emails" element={<Emails />} />
            <Route exact path="leads" element={<LeadsList />} />
            <Route exact path="opportunities" element={<OpportunitiesList />} />
            <Route exact path="offers" element={<OffersList />} />
            <Route exact path="funded-deals" element={<FundedDealsList />} />
          </Route>
          <Route path="/merchants/" element={<MerchantSearch />}>
            {roleBasedDocumentAccess && <Route exact path="documents" element={<DocumentsList />} />}
            <Route exact path="emails" element={<Emails />} />
            <Route exact path="offers" element={<MerchantOffers />} />
            <Route exact path="leads" element={<MerchantLeads />} />
            <Route exact path="opportunities" element={<MerchantOpportunities />} />
            <Route exact path="funded-deals" element={<MerchantFundedDeals />} />
          </Route>
        </Route>
        <Route exact path="/" element={<Login />} />
        <Route path="*" element={<PageNotFound />} />
      </Route>
    </Routes>
  );
};
export default Router;
