import { useMsal } from "@azure/msal-react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useSearchParams } from "react-router-dom";
import { SEARCH_KEY } from "../constants/searchConstants";
import { useCallback, useMemo, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { getAccessToken } from "../services/signInServices";
import {
  getCombinedSearchResuts,
  getMerchantResults,
  getSearchResults,
} from "./Search/actions";
import { SELECTED_DOCUMENT_FILTERS, SET_MERCHANT_DOCUMENT_SELECTED_FILTERS, getDocumentsResults } from "../Layouts/SearchResults/DocumentSearchResult/action";
import SearchInput from "./SuperInput/SearchInput";
import { SET_OFFERS_FILTERS, fetchOffersList } from "../redux/offers/actions";
import { SET_LEADS_SELECTED_FILTERS, fetchLeadList } from "../redux/leads/actions";
import { SET_FUNDED_DEALS_FILTERS, fetchFundedDealsList } from "../redux/fundedDeals/actions";
import { SET_OPPORTUNITIES_FILTERS, fetchOpportunitiesList } from "../redux/opportunities/action";
import { SET_EMAIL_SELECTED_FILTERS } from "./SearchFilters/action";

const GlobalSearchInput = () => {
  const dispatch = useDispatch();
  const { instance, accounts } = useMsal();
  const profileReducerState = useSelector((state) => state.profileReducer);
  const [searchParams, setSearchParams] = useSearchParams();
  const searchKey = searchParams.get(SEARCH_KEY);
  const [inputValue, setInputValue] = useState(searchKey || "");
  const { pathname } = useLocation();
  const activeTab = useMemo(() => pathname.split("/search/")[1], [pathname]);

  const fetchMerchantList = (page = 1, rowsPerPage = 10) => {
    // Fecth MerchantList as per search key
    dispatch(
      getMerchantResults(
        {
          searchValue: inputValue,
          perPage: rowsPerPage,
          skip: (page - 1) * rowsPerPage,
        },
        getAccessToken(instance, accounts)
      )
    );
  };
  const fetchDocuments = (page = 1, rowsPerPage = 10) => {
    // Fecth Documents
    dispatch(
      getDocumentsResults(
        {
          searchValue: inputValue,
          perPage: rowsPerPage,
          skip: (page - 1) * rowsPerPage,
          userRoles: profileReducerState?.profile?.decoded?.roles,
        },
        getAccessToken(instance, accounts),
      )
    );
  };
  const fetchEmailSearchResults = (page = 1, rowsPerPage = 12) => {
    // Fecth EmailSearchResults
    if (profileReducerState && profileReducerState.profile) {
      dispatch(
        getSearchResults(
          {
            searchValue: inputValue,
            perPage: rowsPerPage,
            skip: (page - 1) * rowsPerPage,
          },
          getAccessToken(instance, accounts)
        )
      );
      if (profileReducerState.delegatedEmailIDsDropdown?.value.length > 0 && profileReducerState?.delegatedEmailIDsDropdown?.value.every(data => data !== "NA")) {
        dispatch(
          getCombinedSearchResuts(
            { searchValue: inputValue },
            profileReducerState.delegatedEmailIDsDropdown,
            getAccessToken(instance, accounts)
          )
        );
      }
    }
  }
  const fetchLeads = (page = 1, rowsPerPage = 10) => {
    // Fecth fetchLeads as per search key
    dispatch(
      fetchLeadList(
        {
          searchValue: inputValue,
          perPage: rowsPerPage,
          skip: (page - 1) * rowsPerPage,
          userRoles: profileReducerState?.profile?.decoded?.roles,
        },
        getAccessToken(instance, accounts)
      )
    );
  };
  const fetchOffersResults = (page = 1, rowsPerPage = 10) => {
    // Fecth Offers
    dispatch(
      fetchOffersList(
        {
          searchValue: inputValue,
          perPage: rowsPerPage,
          skip: (page - 1) * rowsPerPage,
          userRoles: profileReducerState?.profile?.decoded?.roles,
        },
        getAccessToken(instance, accounts)
      )
    );
  };
  
  const fetchFundedDeals = (page = 1, rowsPerPage = 10) => {
    // Fecth Funded deals
    dispatch(
      fetchFundedDealsList(
        {
          searchValue: inputValue,
          perPage: rowsPerPage,
          skip: (page - 1) * rowsPerPage,
        },
        getAccessToken(instance, accounts)
      )
    );
  };
  const fetchOpportunities = (page = 1, rowsPerPage = 10) => {
    // Fecth Opportunities
    dispatch(
      fetchOpportunitiesList(
        {
          searchValue: inputValue,
          perPage: rowsPerPage,
          skip: (page - 1) * rowsPerPage,
        },
        getAccessToken(instance, accounts)
      )
    );
  };

  const apiCallMapperAsPerTab = useMemo(() => {
    return new Map([
      ["merchants", fetchMerchantList],
      ["documents", fetchDocuments],
      ["emails", fetchEmailSearchResults],
      ["leads", fetchLeads],
      ["opportunities", fetchOpportunities],
      ["offers", fetchOffersResults],
      ["funded-deals", fetchFundedDeals],
    ]);
  }, [searchKey, inputValue]);

  const handleSearchChange = useCallback(() => {
    dispatch({type: SET_LEADS_SELECTED_FILTERS, payload: null});
    dispatch({type: SET_OPPORTUNITIES_FILTERS, payload: null})
    dispatch({type: SET_OFFERS_FILTERS, payload: null})
    dispatch({type: SET_FUNDED_DEALS_FILTERS, payload: null})
    dispatch({type: SELECTED_DOCUMENT_FILTERS, payload:null});
    dispatch({type: SET_MERCHANT_DOCUMENT_SELECTED_FILTERS, payload:null});
    dispatch({type: SET_EMAIL_SELECTED_FILTERS, payload:null});
    
    if (!inputValue) {
      // if there is not search term - clear searchParams
      searchParams.delete(SEARCH_KEY);
      setSearchParams(searchParams);
      return;
    }
    // Set searchParam with search term
    searchParams.set(SEARCH_KEY, inputValue);
    setSearchParams(searchParams);

    // API call according selected tabs and searched terms
    apiCallMapperAsPerTab.get(activeTab)();
  }, [inputValue, activeTab]);

  return (
    <Row className="flex-grow-1 mx-2">
      <Col sm={0} md={2} lg={3}></Col>
      <Col className="position-relative">
        <SearchInput
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          searchHandler={handleSearchChange}
        />
      </Col>
      <Col sm={0} md={2} lg={3}></Col>
    </Row>
  );
};

export default GlobalSearchInput;
