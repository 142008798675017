import { useMemo } from "react";
import { useSelector } from "react-redux";

const profileSelector = (state) => state.profileReducer;

const useAllowedRoles = (allowedRoles = null) => {
  const profileReducerState = useSelector(profileSelector);

  const userRoles = profileReducerState?.profile?.decoded?.roles;

  function checkRoleAccess(userRoles, routeRoles) {
    return !!userRoles?.find((role) => routeRoles?.includes(role));
  }

  const isRoleAllowed = useMemo(
    () => checkRoleAccess(userRoles, allowedRoles),
    [userRoles, allowedRoles]
  );

  return isRoleAllowed;
};

export default useAllowedRoles;
