import {
  SET_FUNDED_DEALS_DATA,
  SET_FUNDED_DEALS_FILTERS,
  SET_MERCHANTS_FUNDED_DEALS_DATA,
  SET_MERCHANT_FUNDED_DEALS_FILTERS,
} from "./actions";

const initialState = {
  fundedDealsData: {},
  merchantFundedDealsData: {},
  fundedDealsFilters: null,
  merchantFundedDealsFilters: null,
};

const fundedDealsReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_FUNDED_DEALS_DATA:
      return { ...state, fundedDealsData: payload };

    case SET_MERCHANTS_FUNDED_DEALS_DATA:
      return { ...state, merchantFundedDealsData: payload };
    
    case SET_FUNDED_DEALS_FILTERS:
      return {...state, fundedDealsFilters: payload}
    
    case SET_MERCHANT_FUNDED_DEALS_FILTERS:
      return {...state, merchantFundedDealsFilters: payload}

    default:
      return state;
  }
};

export default fundedDealsReducer;
