import * as React from "react";
const BookmarkIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={14}
    height={19}
    fill="none"
    {...props}
  >
    <path
      stroke="#1C2120"
      strokeLinecap="round"
      strokeWidth={1.5}
      d="M1 3.6v13.947a.5.5 0 0 0 .744.436l4.279-2.396a2 2 0 0 1 1.954 0l4.279 2.396a.5.5 0 0 0 .744-.436V3.6a2 2 0 0 0-2-2H3a2 2 0 0 0-2 2Z"
    />
  </svg>
);
export default BookmarkIcon;
