import { getDelegatedConversationView } from "../../services/filterServices";
import { LOAD_MORE_COMBINE_RESULT } from "../../Layouts/SearchResults/EmailSearchResult/actions";
import {
	APPLY_SINGLE_FILTER,
	APPLY_MUTI_FILTER,
	ADD_SEARCH_TYPE,
	GET_FILTERED_RESULTS,
	LOAD_MORE_FILTERED_RESULT,
	CLEAR_ADDITIONAL_FILTERS,
	CHANGE_EMAIL_VIEW,
	GET_DELEGATED_EMAILS_FOR_SELECTED_ID,
	GET_FILTERED_DELEGATED_EMAILS_FOR_ALL_ID,
	REMOVE_DELEGATED_EMAIL_LIST,
	SELF_DELEGATED_EMAIL_LIST,
	LOAD_MORE_COMBINE_RESULT_SELECTED_ID,
	LOAD_MORE_COMBINE_FILTERED_RESULT_SELECTED_ID,
	SET_OLD_OR_NEW_SORTING, 
	SET_EMAIL_SELECTED_FILTERS, 
	SET_MERCHANT_EMAIL_SELECTED_FILTERS
} from "./action";
import { NEWEST } from "../../utils/constant";

let initialFilterState = {
	newOrOldSelectedSortValue: NEWEST,
	conversationViewMode: true,
	merchantEmailSelectedFilters: null,
	emailSelectedFilters: null
};

export const filterReducer = (state = initialFilterState, action) => {
	let newState;
	const { payload, type } = action;

	switch (type) {
		case REMOVE_DELEGATED_EMAIL_LIST:
			return {
				...state,
				typeOfSelectedEmails: undefined,
				selectedEmail: undefined,
				conversations: getDelegatedConversationView(
					state.delegatedEmailIdMap,
					state.filteredResults.data.value
				),
			};
		case GET_DELEGATED_EMAILS_FOR_SELECTED_ID:
			let selectedEmailState = {
				...state,
				delegatedEmailsForSelectedId: payload?.list,
				selectedEmail: payload?.id,
				typeOfSelectedEmails: "delegated",
			};

			if (!selectedEmailState?.delegatedEmailIdMap) {
				selectedEmailState.delegatedEmailIdMap = {};
				selectedEmailState.delegatedEmailIdMap[payload?.id] = payload?.list;
			}

			const newMap = {};

			newMap[payload?.id] = payload?.list || {
				"@odata.context": "",
				value: [],
			};

			selectedEmailState = {
				...selectedEmailState,
				conversations: getDelegatedConversationView(newMap, []),
			};

			return selectedEmailState;
		case GET_FILTERED_DELEGATED_EMAILS_FOR_ALL_ID:
			return {
				...state,
				delegatedEmailIdMap: payload,
				conversations: getDelegatedConversationView(
					payload,
					state.filteredResults?.data?.value || []
				),
			};
		case LOAD_MORE_COMBINE_RESULT_SELECTED_ID:
			return {
				...state,
				delegatedEmailIdMap: payload,
				conversations: getDelegatedConversationView(
					payload,
					state.filteredResults?.data?.value || []
				),
			};
		case LOAD_MORE_COMBINE_FILTERED_RESULT_SELECTED_ID:
			return {
				...state,
				delegatedEmailIdMap: payload,
				conversations: getDelegatedConversationView(
					payload,
					state.filteredResults?.data?.value || []
				),
			};
		case SELF_DELEGATED_EMAIL_LIST:
			return {
				...state,
				typeOfSelectedEmails: "self",
				selectedEmail: "self",
				conversations: getDelegatedConversationView({}, state.filteredResults.data.value),
			};
		case CHANGE_EMAIL_VIEW:
			return {
				...state,
				conversationViewMode: payload.value,
			};
		case ADD_SEARCH_TYPE:
			return {
				...state,
				...payload,
				filterApplied: false,
			};

		case APPLY_SINGLE_FILTER:
			newState = {
				...state,
			};
			newState[payload.key] = payload.value;
			return newState;

		case APPLY_MUTI_FILTER:
			newState = { ...state };
			payload.map((element, index) => (newState[element.key] = element.value));
			return newState;

		case LOAD_MORE_COMBINE_RESULT:
			const loadedMoreState = { ...state };
			const keys = Object.keys(payload);
			for (let key of keys) {
				const data = payload[key];
				loadedMoreState.delegatedEmailIdMap[key].value = [
					...loadedMoreState.delegatedEmailIdMap[key].value,
					...data.value,
				];
				loadedMoreState.delegatedEmailIdMap[key]["@odata.nextLink"] =
					data["@odata.nextLink"];
				loadedMoreState.delegatedEmailIdMap[key]["@odata.context"] = data["@odata.context"];
			}
			return {
				...loadedMoreState,
				conversations: getDelegatedConversationView(
					loadedMoreState.delegatedEmailIdMap,
					state.filteredResults.data.value
				),
			};
		case GET_FILTERED_RESULTS:
			let filters = {};
			if (payload.filters) {
				filters = payload.filters;
			}			
			const response = payload.response;
			
			const { value } = response?.data;

			// const filteredConversations = getConversations(value);

			const newConversationState = {
				...state,
				filteredResults: response,
				filterApplied: payload.filterApplied,
				...filters,
				conversations: getDelegatedConversationView(state.delegatedEmailIdMap, value),
				conversationViewMode: payload?.conversationViewMode || state.conversationViewMode
			};
			return newConversationState;

		case LOAD_MORE_FILTERED_RESULT:
			const loadedMoreArray = [
				...state.filteredResults?.data?.value,
				...payload?.data?.value,
			];

			// const moreConversations = getConversations(loadedMoreArray);

			newState = {
				...state,
				filteredResults: {
					...state.filteredResults,
					data: {
						value: loadedMoreArray,
					},
				},
			};
			newState = {
				...newState,
				conversations: getDelegatedConversationView(
					state?.delegatedEmailIdMap,
					newState?.filteredResults?.data?.value
				),
			};

			if (payload.data["@odata.nextLink"]) {
				newState.filteredResults.data["@odata.nextLink"] = payload.data["@odata.nextLink"];
			} else {
				newState.filteredResults.data["@odata.nextLink"] = null;
			}

			if (payload.data["@odata.context"]) {
				newState.filteredResults.data["@odata.context"] = payload.data["@odata.context"];
			} else {
				newState.filteredResults.data["@odata.context"] = null;
			}

			return newState;

		case CLEAR_ADDITIONAL_FILTERS:
			const clearedListOfEmails = payload.data.value;
			// let clearedConversations = getConversations(clearedListOfEmails);

			return {
				...state,
				filteredResults: payload,
				filterApplied: false,
				from: [
					{
						value: "All",
						label: "All",
					},
				],
				to: [
					{
						value: "All",
						label: "All",
					},
				],
				startDate: null,
				endDate: null,
				conversations: getDelegatedConversationView(
					payload.delegatedEmailIdMap,
					payload.data.value
				),
				delegatedEmailIdMap: payload.delegatedEmailIdMap,
			};
		case SET_OLD_OR_NEW_SORTING:			
		return {
			...state, 
			newOrOldSelectedSortValue: action.payload
		}

		case SET_EMAIL_SELECTED_FILTERS:        
        return { ...state, emailSelectedFilters: payload };

        case SET_MERCHANT_EMAIL_SELECTED_FILTERS:        
        return { ...state, merchantEmailSelectedFilters: payload };

		
		default:
			return state;
	}
};
