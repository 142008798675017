export const SHOW_LOADING = "SHOW_LOADING";

export const HIDE_LOADING = "HIDE_LOADING";

export const showLoading = () =>
  function (dispatch) {
    dispatch({ type: SHOW_LOADING });
  };

export const hideLoading = () =>
  function (dispatch) {
    dispatch({ type: HIDE_LOADING });
  };
