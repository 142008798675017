import { loginRequest } from "../authConfig";
import { hideLoading, showLoading } from "../components/Loading/action";
import { callMsGraph } from "../graph";
import jwtDecode from "jwt-decode";
export const UPDATE_PROFILE_DATA = "UPDATE_PROFILE_DATA";

export const requestProfileData = (instance, accounts) =>
  async function (dispatch) {
    dispatch(showLoading());

    // Silently acquires an access token which is then attached to a request for MS Graph data
    const { accessToken, decoded, idToken } = await getTokens(instance, accounts);

    const response = await callMsGraph(accessToken);
    response["idToken"] = idToken;
    response.decoded = {
      ...decoded,
    };
    dispatch({
      type: UPDATE_PROFILE_DATA,
      payload: response,
    });

    dispatch(hideLoading());
    return accessToken;
  };

export const getTokens = async (instance, accounts) => {
  // Silently acquires an access token which is then attached to a request for MS Graph data
  let response = await instance.acquireTokenSilent({
    ...loginRequest,
    account: accounts[0],
  });
  let { accessToken, idToken} = await response;
  const decoded = idToken ? jwtDecode(idToken) : {};

  return {accessToken, decoded, idToken};
}

export const getAccessToken = async (instance, accounts) => {
  const { accessToken } = await getTokens(instance, accounts);

  return accessToken;
};

