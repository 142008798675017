export const APPLY_SINGLE_FILTER = 'APPLY_SINGLE_FILTER';

export const APPLY_MUTI_FILTER = 'APPLY_MUTI_FILTER';

export const ADD_SEARCH_TYPE = 'ADD_SEARCH_TYPE';

export const GET_FILTERED_RESULTS = 'GET_FILTERED_RESULTS';

export const LOAD_MORE_FILTERED_RESULT = 'LOAD_MORE_FILTERED_RESULT';

export const CLEAR_ADDITIONAL_FILTERS = 'CLEAR_ADDITIONAL_FILTERS';

export const CHANGE_EMAIL_VIEW = "CHANGE_EMAIL_VIEW";

export const GET_DELEGATED_EMAILS_FOR_SELECTED_ID = "GET_DELEGATED_EMAILS_FOR_SELECTED_ID";
export const GET_FILTERED_DELEGATED_EMAILS_FOR_ALL_ID = "GET_FILTERED_DELEGATED_EMAILS_FOR_ALL_ID";
export const REMOVE_DELEGATED_EMAIL_LIST = "REMOVE_DELEGATED_EMAIL_LIST";
export const SELF_DELEGATED_EMAIL_LIST = "SELF_DELEGATED_EMAIL_LIST";
export const GET_DELEGATED_EMAIL_IDS_FOR_DROPDOWN = "GET_DELEGATED_EMAIL_IDS_FOR_DROPDOWN";

export const LOAD_MORE_COMBINE_RESULT_SELECTED_ID = 'LOAD_MORE_COMBINE_RESULT_SELECTED_ID';
export const LOAD_MORE_COMBINE_FILTERED_RESULT_SELECTED_ID = 'LOAD_MORE_COMBINE_FILTERED_RESULT_SELECTED_ID';
export const SET_OLD_OR_NEW_SORTING = 'SET_OLD_OR_NEW_SORTING';
export const SET_EMAIL_SELECTED_FILTERS = 'SET_EMAIL_SELECTED_FILTERS';
export const SET_MERCHANT_EMAIL_SELECTED_FILTERS = 'SET_MERCHANT_EMAIL_SELECTED_FILTERS';

export const changeViewMode = (value) => function(dispatch) {
    dispatch({type: CHANGE_EMAIL_VIEW,payload: {value}});
}

export const applySingleFilter = (key, value) => function(dispatch) {
    let payload = {
        key,
        value
    };

    dispatch({
        type: APPLY_SINGLE_FILTER,
        payload
    });
}

export const selfSelectedDelegatedEmails = () => function(dispatch) {
    dispatch({
        type: SELF_DELEGATED_EMAIL_LIST,
        payload: "self"
    });
}