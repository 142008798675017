import axios from "axios";
import { hideLoading, showLoading } from "../../../components/Loading/action";
import { workflowActions } from "../../../constants/categoryContants";
import { LEADS_URL } from "../../../utils/apiEndpoints";

export const GET_ADMIN_SEARCH_RESULT = "GET_ADMIN_SEARCH_RESULT";
export const LOAD_MORE_ADMIN_SEARCH_RESULT = "LOAD_MORE_ADMIN_SEARCH_RESULT";
export const GET_LEAD_RESULT = "GET_LEAD_RESULT";

export const getAdminSearchResult = (searchValues, tokenPromise) =>
  async function (dispatch) {
    const {
      searchValue,
      status = `${workflowActions.adminNew},${workflowActions.adminCancel}`,
      filterKeys = null,
    } = searchValues;
    console.log("filterKeys getAdminSearchResult", filterKeys);
    let params = {status};

    if (searchValue) {
       params["keyword"] = searchValue;
    }
    if(filterKeys){
      params = {...filterKeys};
    }
    console.log("leads params", params)

    const accessToken = await tokenPromise;
    const axiosConfig = {
      url: LEADS_URL,
      method: "get",
      params,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    dispatch(showLoading());

    axios(axiosConfig)
      .then((response) => {
        console.log("response = ", response);
        dispatch({
          type: GET_ADMIN_SEARCH_RESULT,
          payload: response.data,
        });

        dispatch(hideLoading());
      })
      .catch((error) => {
        console.log(error)
        dispatch(hideLoading());
      });
  };

export const loadMoreAdminSearchResult = (nextLinkURL, tokenPromise) =>
  async function (dispatch) {
    const accessToken = await tokenPromise;
    const axiosConfig = {
      url: `/api${nextLinkURL}`,
      // url: `http://localhost:8080${nextLinkURL}`,
      // url: "http://localhost:8080/leads",
      method: "get",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    dispatch(showLoading());

    axios(axiosConfig)
      .then((response) => {
        console.log("response = ", response);
        dispatch({
          type: LOAD_MORE_ADMIN_SEARCH_RESULT,
          payload: response.data,
        });

        dispatch(hideLoading());
      })
      .catch((error) => {
        console.log(error);
        dispatch(hideLoading());
      });
  };

export const getLeadDetails = (tokenPromise, leadId) =>
  async function (dispatch) {
    const accessToken = await tokenPromise;
    const axiosConfig = {
      url: `/api/leads/${leadId}`,
      // url: `http://localhost:8080/leads/${leadId}`,
      method: "get",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    dispatch(showLoading());

    axios(axiosConfig)
      .then((response) => {
        console.log("response = ", response);
        dispatch({
          type: GET_LEAD_RESULT,
          payload: response.data,
        });
        dispatch(hideLoading());
      })
      .catch((error) => {
        console.log(error);
        dispatch(hideLoading());
      });
  };

export const updateAATMTable = (tokenPromise, requestBody) =>
  async function (dispatch) {
    const accessToken = await tokenPromise;
    const axiosConfig = {
      url: `/api/editLead`,
      // url: `http://localhost:8080/editLead`,
      method: "PUT",
      data: requestBody,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    dispatch(showLoading());

    await axios(axiosConfig)
      .then((response) => {
        console.log("response = ", response);
        dispatch(getLeadDetails(tokenPromise, requestBody.leadId));
        // dispatch({
        //   type: GET_LEAD_RESULT,
        //   payload: response.data,
        // });
        dispatch(hideLoading());
      })
      .catch((error) => {
        console.log(error);
        dispatch(hideLoading());
      });
  };

export const saveAdminLeadData = (tokenPromise, requestBody, setEditEnabled) =>
  async function (dispatch) {
    const accessToken = await tokenPromise;

    const axiosConfig = {
      url: `/api/patchLead`,
      // url: `http://localhost:8080/patchLead`,
      method: "PATCH",
      data: requestBody,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    dispatch(showLoading());

    await axios(axiosConfig)
      .then((response) => {
        console.log("response = ", response);
        dispatch(getLeadDetails(tokenPromise, requestBody.LeadId));
        // dispatch({
        //   type: GET_LEAD_RESULT,
        //   payload: requestBody,
        // });
        dispatch(hideLoading());
        setEditEnabled(false);
      })
      .catch((error) => {
        console.log(error);
        dispatch(hideLoading());
        setEditEnabled(false);
      });
  };

export const submitAdminLeadData = (tokenPromise, requestBody) =>
  async function (dispatch) {
    const accessToken = await tokenPromise;

    const axiosConfig = {
      url: `/api/submitLead`,
      // url: `http://localhost:8080/submitLead`,
      method: "PATCH",
      data: requestBody,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    dispatch(showLoading());

    await axios(axiosConfig)
      .then((response) => {
        console.log("response = ", response);
        dispatch(getLeadDetails(tokenPromise, requestBody.LeadId));
        dispatch(hideLoading());
      })
      .catch((error) => {
        console.log(error);
        dispatch(hideLoading());
      });
  };

export const cancelAdminLeadData = (
  tokenPromise,
  requestBody,
  setEditEnabled
) =>
  async function (dispatch) {
    const accessToken = await tokenPromise;

    const axiosConfig = {
      url: `/api/cancelLead`,
      // url: `http://localhost:8080/cancelLead`,
      method: "PATCH",
      data: requestBody,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    dispatch(showLoading());

    await axios(axiosConfig)
      .then((response) => {
        console.log("response = ", response);
        dispatch(getLeadDetails(tokenPromise, requestBody.LeadId));
        dispatch(hideLoading());
        setEditEnabled(false);
      })
      .catch((error) => {
        console.log(error);
        dispatch(hideLoading());
        setEditEnabled(false);
      });
  };
