import { hideLoading, showLoading } from "../../components/Loading/action";
import { fetchFundedDealsAPI, getFundedDealsFilterString } from "../../services/fundedDealsServices";

export const SET_FUNDED_DEALS_DATA = "SET_FUNDED_DEALS_DATA";
export const SET_MERCHANTS_FUNDED_DEALS_DATA = "SET_MERCHANTS_FUNDED_DEALS_DATA";
export const SET_FUNDED_DEALS_FILTERS = "SET_FUNDED_DEALS_FILTERS";
export const SET_MERCHANT_FUNDED_DEALS_FILTERS = "SET_MERCHANT_FUNDED_DEALS_FILTERS";

export const fetchFundedDealsList =
  (searchTerm, tokenPromise) => async (dispatch, getState) => {
    const { fundedDealsFilters: filterKeys } = getState()["fundedDealsReducer"];
    const { perPage = 10, skip = 0, searchValue } = searchTerm;
    const searchString = searchValue?.replace(/\s+/g, " ").trim().split(" ").join(" | ") || "";

    const updatedFilterKeys = filterKeys 
      ? `stage eq 'Funded' and ${getFundedDealsFilterString({...filterKeys, isFundedDeals: true})}` 
      : "stage eq 'Funded'"

    dispatch(showLoading());
    try {
      const response = await fetchFundedDealsAPI(
        { searchValue: searchString, filterKeys: updatedFilterKeys, perPage, skip, orderby:"fundedDate" },
        tokenPromise
      );
      dispatch({ type: SET_FUNDED_DEALS_DATA, payload: response.data });
      return response.data;
    } catch (error) {
      console.info("error fetchFundedDealsList =>", error);
      return error;
    } finally {
      dispatch(hideLoading());
    }
  };

export const fetchMerchantFundedDealsList =
  (searchTerm, tokenPromise) => async (dispatch, getState) => {
    const { merchantFundedDealsFilters: filterKeys } = getState()["fundedDealsReducer"];
    const { perPage = 10, skip = 0, searchValue, merchantId } = searchTerm;
    const searchString = searchValue?.replace(/\s+/g, " ").trim().split(" ").join(" | ") || "";

    const updatedFilterKeys = filterKeys 
      ? `merchantId eq '${merchantId}' and stage eq 'Funded' and ${getFundedDealsFilterString({...filterKeys, isFundedDeals: true})}` 
      : `merchantId eq '${merchantId}' and stage eq 'Funded'`

    dispatch(showLoading());
    try {
      const response = await fetchFundedDealsAPI(
        { searchValue:searchString, filterKeys:updatedFilterKeys, perPage, skip,  orderby:"fundedDate" },
        tokenPromise
      );
      dispatch({ type: SET_MERCHANTS_FUNDED_DEALS_DATA, payload: response.data });
      return response.data;
    } catch (error) {
      console.info("error fetchMerchantFundedDealsList =>", error);
      return error;
    } finally {
      dispatch(hideLoading());
    }
  };
