import {
  GET_ADMIN_SEARCH_RESULT,
  GET_LEAD_RESULT,
  LOAD_MORE_ADMIN_SEARCH_RESULT,
} from "./action";
let initialState = {};

const adminReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_ADMIN_SEARCH_RESULT:
      return {
        ...state,
        adminSearchTableData: payload,
      };

    case LOAD_MORE_ADMIN_SEARCH_RESULT:
      return {
        ...state,
        adminSearchTableData: {
          currentPageNumber: payload.currentPageNumber,
          nextLink: payload.nextLink,
          leadList: [
            ...state.adminSearchTableData.leadList,
            ...payload.leadList,
          ],
        },
      };

    case GET_LEAD_RESULT:
      return {
        ...state,
        leadDetails: payload,
      };
    default:
      return state;
  }
};

export default adminReducer;
