import {
  GET_SEARCH_RESULT,
  LOAD_MORE_SEARCH_RESULT,
  GET_DELEGATED_EMAILS_FOR_SELECTED_ID,
  REMOVE_DELEGATED_EMAIL_LIST,
  GET_DELEGATED_EMAILS_FOR_ALL_ID,
  SELF_DELEGATED_EMAIL_LIST,
} from "./actions";

const initialSearchState = {};

export const searchReducer = (state = initialSearchState, action) => {
  const { type, payload } = action;

  switch (type) {
    case REMOVE_DELEGATED_EMAIL_LIST:
      return {
        ...state,
        typeOfSelectedEmails: undefined,
        selectedEmail: undefined,
      };

    case GET_DELEGATED_EMAILS_FOR_ALL_ID:
      let newDelegatedState = {
        ...state,
        delegatedEmailIdMap: {...payload},
      };
      return newDelegatedState;

    case GET_DELEGATED_EMAILS_FOR_SELECTED_ID:
      return {
        ...state,
        delegatedEmailsForSelectedId: payload.list,
        typeOfSelectedEmails: "delegated",
        selectedEmail: payload.id,
      };
    case SELF_DELEGATED_EMAIL_LIST:
      return {
        ...state,
        typeOfSelectedEmails: "self",
        selectedEmail: "self",
      };
    case GET_SEARCH_RESULT:
      return {
        ...state,
        ...payload,
      };

    case LOAD_MORE_SEARCH_RESULT:
      const newState = {
        ...state,
        data: {
          value: [...state.data.value, ...payload.data.value],
        },
      };

      if (payload.data["@odata.nextLink"]) {
        newState.data["@odata.nextLink"] = payload.data["@odata.nextLink"];
      } else {
        newState.data["@odata.nextLink"] = null;
      }

      if (payload.data["@odata.context"]) {
        newState.data["@odata.context"] = payload.data["@odata.context"];
      } else {
        newState.data["@odata.context"] = null;
      }

      return newState;

    default:
      return state;
  }
};
