export const SEARCH_KEY = "searchKey";
export const MERCHANT_NAME = "merchantName";
export const DOCUCMENTS = 'documents';
export const MERCHANT_ID = "merchantId";

export const SEARCH_TABS = {
	merchants: "Merchants",
	documents: "Documents",
	emails: "Emails",
	leads: "Leads",
	opportunities: "Opportunities",
	offers: "Offers",
	'funded-deals': "Funded Deals"
};
