import React from 'react'
import ReactDOM from 'react-dom/client'
import 'bootstrap/dist/css/bootstrap.min.css'
import { PublicClientApplication } from '@azure/msal-browser'
import { MsalProvider } from '@azure/msal-react'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { msalConfig } from './authConfig'
import configureStore from './redux/store' 
import App from './App'

const store = configureStore()

const msalInstance = new PublicClientApplication(msalConfig)

const root = ReactDOM.createRoot(document.getElementById('root'))
console.log("ENV, path ==>", process.env.NODE_ENV, process.env.REACT_APP_AZURE_API_BASE_URL, process.env.REACT_APP_PUBLIC_URL, process.env.PUBLIC_URL)
/**
 * We recommend wrapping most or all of your components in the MsalProvider component. It's best to render the MsalProvider as close to the root as possible.
 */
root.render(
    <BrowserRouter>
      <Provider store={store}>
        <MsalProvider instance={msalInstance}>
          <App />
        </MsalProvider>
      </Provider>
    </BrowserRouter>
)
