import {
  GET_DOCUMENT_SEARCH,
  SELECT_MULTIPLE_DOCUMENTS,
  SELECTED_DOCUMENT_FILTERS,
  MERCHANT_SEARCH_KEY,
  SET_MERCHANT_DOCUMENT_SELECTED_FILTERS,
} from "./action";

let initialState = {
  selectedDocuments: [],
  selectedDocumentFilters: null,
  merchantSearchKey: "",
  merchantDocumentSelectedFilters: null
};

export const documentReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_DOCUMENT_SEARCH:
      return {
        ...state,
        ...payload.data,
        filterApplied: !!state.selectedDocumentFilters,
        filteredResults: payload.data,
      };
    case SELECT_MULTIPLE_DOCUMENTS: {
      return { ...state, selectedDocuments: payload };
    }
    case SELECTED_DOCUMENT_FILTERS: {
      return {
        ...state,
        selectedDocumentFilters: payload?.filterValues,
        filterApplied: payload?.filterApplied || false,
      };
    }
    case MERCHANT_SEARCH_KEY: {
      return { ...state, merchantSearchKey: payload };
    }

   case SET_MERCHANT_DOCUMENT_SELECTED_FILTERS:           
    return { ...state, merchantDocumentSelectedFilters: payload };

    default:
      return state;
  }
};
