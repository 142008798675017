import React from "react";
import { Form, InputGroup } from "react-bootstrap";
import SearchIcon from "../../images/SearchIcon";

export default function SearchInput({ className = "", inputClassName, placeholder = "Search...", value = "", onChange = () => { }, searchHandler = () => { } }) {
    return (
        <InputGroup size="sm" className={`mb-3 search-input-box ${className ? className:""}`}>
            <Form.Control
                placeholder={placeholder}
                aria-label="Small"
                aria-describedby="inputGroup-sizing-sm"
                className={`text-black bg-transparent search-text-input border-0 fs-sm fw-normal ${inputClassName ? inputClassName:""}`}
                value={value}
                onChange={onChange}
                onKeyDown={(e) => e.key === "Enter" && searchHandler()}
            />
            <InputGroup.Text
                id="inputGroup-sizing-sm"
                className="bg-transparent border-0"
            >
                <SearchIcon role="button" onClick={searchHandler} />
            </InputGroup.Text>
        </InputGroup>
    );
}
