import { hideLoading, showLoading } from "../../components/Loading/action";
import { fetchFundedDealsAPI, getFundedDealsFilterString } from "../../services/fundedDealsServices";
import { FUNDED, Lead } from "../../utils/constant";

export const SET_OPPORTUNITIES_DATA = "SET_OPPORTUNITIES_DATA";
export const SET_MERCHANTS_OPPORTUNITIES_DATA = "SET_MERCHANTS_OPPORTUNITIES_DATA";
export const SET_OPPORTUNITIES_FILTERS = "SET_OPPORTUNITIES_FILTERS";
export const SET_MERCHANT_OPPORTUNITIES_FILTERS = "SET_MERCHANT_OPPORTUNITIES_FILTERS";

export const fetchOpportunitiesList = (searchTerm, tokenPromise) => async (dispatch, getState) => {
    const { opportunitiesFilters: filterKeys } = getState()["opportunitiesReducer"];
    const { perPage = 10, skip = 0, searchValue } = searchTerm;
    const searchString = searchValue?.replace(/\s+/g, " ").trim().split(" ").join(" | ") || "";

    dispatch(showLoading());
    try {
      const filters = filterKeys 
      ? `status ne '${Lead}' and status ne '${FUNDED}' and ${getFundedDealsFilterString(filterKeys)}` 
      : `status ne '${Lead}' and status ne '${FUNDED}'`
      
      const response = await fetchFundedDealsAPI(
        { searchValue: searchString, filterKeys:filters, perPage, skip, orderby:"submittedOn"},
        tokenPromise
      );      
      dispatch({ type: SET_OPPORTUNITIES_DATA, payload: response.data });
    } catch (error) {
      console.info("error fetchOpportunitiesList =>", error);
      return error;
    } finally {
      dispatch(hideLoading());
    }
  };

export const fetchMerchantOpportunitiesList = (searchTerm, tokenPromise) => async (dispatch, getState) => {
    const { merchantOpportunitiesFilters: filterKeys } = getState()["opportunitiesReducer"];
    const { perPage = 10, skip = 0, searchValue, merchantId } = searchTerm;
    const searchString = searchValue?.replace(/\s+/g, " ").trim().split(" ").join(" | ") || "";


    dispatch(showLoading());
    try {
    const filters = filterKeys 
    ? `merchantId eq '${merchantId}' and status ne '${Lead}' and status ne '${FUNDED}' and ${getFundedDealsFilterString(filterKeys)}` 
    : `merchantId eq '${merchantId}' and status ne '${Lead}' and status ne '${FUNDED}'`

      const response = await fetchFundedDealsAPI(
        { searchValue:searchString, filterKeys:filters, perPage, skip, orderby:"submittedOn" },
        tokenPromise
      );
      dispatch({ type: SET_MERCHANTS_OPPORTUNITIES_DATA, payload: response.data });
    } catch (error) {
      console.info("error fetchMerchantFundedDealsList =>", error);
      return error;
    } finally {
      dispatch(hideLoading());
    }
  };
