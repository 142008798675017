import axios from "../../config/axiosInterceptor";

export const GET_DELEGATED_EMAIL_IDS_FOR_DROPDOWN = "GET_DELEGATED_EMAIL_IDS_FOR_DROPDOWN";
export const FAILED_GET_DELEGATED_EMAIL_IDS_FOR_DROPDOWN = "FAILED_GET_DELEGATED_EMAIL_IDS_FOR_DROPDOWN";

export const getDelegatedEmailIDs = (id, tokenPromise) =>
  function (dispatch) {
    if (!id) return;
    let n_id = id.toLowerCase();
    const API_URL = `/delegates?$filter=PrimaryEmail eq '${n_id}'`;

    tokenPromise
      .then((accessToken) => {
        const axiosConfig = {
          method: "GET",
          url: API_URL,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };

        axios(axiosConfig)
          .then((response) => {
            dispatch({
              type: GET_DELEGATED_EMAIL_IDS_FOR_DROPDOWN,
              payload: response.data,
            });
          })
          .catch((error) => {
            dispatch({
              type: FAILED_GET_DELEGATED_EMAIL_IDS_FOR_DROPDOWN,
            });
            console.log("delegate api failed = ", error);
          });
      })
      .catch((error) => {
        console.log("auth error = ", error);
      });
  };
