import {
  SET_MERCHANTS_OFFERS_DATA,
  SET_MERCHANT_OFFERS_FILTERS,
  SET_OFFERS_DATA,
  SET_OFFERS_FILTERS,
} from "./actions";

const initialState = {
  offersData: {},
  merchantOffersData: {},
  offersFilters: null,
  merchantOffersFilters: null,
};

const offerReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_OFFERS_DATA:
      return { ...state, offersData: payload };

    case SET_MERCHANTS_OFFERS_DATA:
      return { ...state, merchantOffersData: payload };

    case SET_OFFERS_FILTERS:
      return { ...state, offersFilters: payload };

    case SET_MERCHANT_OFFERS_FILTERS:
      return { ...state, merchantOffersFilters: payload };

    default:
      return state;
  }
};

export default offerReducer;
