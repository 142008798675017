import CheckMarkIcon from "../images/CheckMarkIcon";
import DocumentIcon from "../images/DocumentIcon";
import DollerIcon from "../images/DollerIcon";
import SettingsIcon from "../images/SettingsIcon";

export const SEARCH_TIME_OUT = 500;
export const DATE_FORMAT_WITH_TIME = 'MM/DD/YYYY, h:mm:ss A';
export const DATE_FORMAT = 'MM/DD/YYYY';
export const CURRENCY_SYMBOL = "$"
export const fileTypesForPreview = ["jpg", "jpeg", "png", 'gif', "pdf"];
export const imageFileTypes = ["jpg", "jpeg", "png", 'gif'];

export const initialDateSelectionRange = [
  {
    key: "selection",
  },
];
export const defaultFilePath = "https://www.africau.edu/images/default/sample.pdf"

export const SEARCH_PATH = 'search';

export const OLDEST = 'Oldest';
export const NEWEST = 'Newest';
export const CASH = 'Cash';

export const EMAIL_NEW_OLD_SORTING_OPTION = [NEWEST, OLDEST]

export const sourceSystemText = {
  'JF':'JotForm',
  'OM':'Lead Source'
}
export const CASH_MORGAN_ID = 'cash@morgancash.com';
export const Lead = 'Lead';
export const FUNDED = 'Funded';

export const COLOR_AS_PER_OPPORTUNITY_STATUS = new Map([
  [
    "Open",
    {
      border: "border-blue-lighter",
      bg: "blue-light",
      color: "text-blue-dark",
    },
  ],
  [
    "Closed",
    {
      border: "border-gray-dark-300",
      bg: "bg-gray-dark-500",
      color: "text-gray-dark-100",
    },
  ],
  [
    "Funded",
    {
      border: "border-green-lighter",
      bg: "green-light-100",
      color: "text-green-dark",
    },
  ],
  [
    "Pending",
    { border: "border-blue-lighter", bg: "blue-light", color: "text-blue-dark" },
  ],
  [
    "Lost",
    { border: "border-red-lighter", bg: "red-light", color: "text-red-dark" },
  ],
  [
    "Rejected",
    { border: "border-red-lighter", bg: "red-light", color: "text-red-dark" },
  ],
  [
    "Declined",
    { border: "border-red-lighter", bg: "red-light", color: "text-red-dark" },
  ],
  [
    "Approved",
    {
      border: "border-green-lighter",
      bg: "green-light-100",
      color: "text-green-dark",
    },
  ],
]);

// Role Selector 
export const SUPER_ADMIN = 'admin';
export const SALS_REP = 'sales-rep';

export const OFF_SHORE_ADMIN = 'off-shore-admin';
export const ADDITIONAL_REVIEWER = 'additional-reviewer';

export const ROLES_DETAILS_MAPPER = new Map([
  [
    SALS_REP,
    {
      title: "Sales representative",
      subTitle: "Describe",
      icon: <DollerIcon />,
    },
  ],
  [
    OFF_SHORE_ADMIN,
    {
      title: "Offshore admin",
      subTitle: "Responsible for new leads",
      icon: <DocumentIcon />,
    },
  ],
  [
    SUPER_ADMIN,
    {
      title: "Super admin",
      subTitle: "Responsible for missing data",
      icon: <SettingsIcon />,
    },
  ],
  [
    ADDITIONAL_REVIEWER,
    {
      title: "Additional reviewer",
      subTitle: "Responsible for final check",
      icon: <CheckMarkIcon />,
    },
  ],
]);
