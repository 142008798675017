import { GET_MERCHANT_DATA_FAILURE, GET_MERCHANT_DATA_LOADING, GET_MERCHANT_DATA_SUCCESS } from "./actions";

const initialSearchState = {
    success: false,
    loading: false,
    failure: false
};

export const merchantReducer = (state = initialSearchState, action) => {
    const { type, payload } = action;

    switch (type) {
        case GET_MERCHANT_DATA_SUCCESS: {
            return {
                ...state,
                success: true,
                loading: false,
                failure: false,
                data: payload,
            }
        }

        case GET_MERCHANT_DATA_FAILURE: {
            return {
                ...state,
                success: false,
                loading: false,
                failure: true,
                message: payload
            }
        }

        case GET_MERCHANT_DATA_LOADING: {
            return {
                ...state,
                success: false,
                loading: true,
                failure: false
            }
        }

        default: {
            return state;
        }
    }

}