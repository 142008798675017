import { ListGroup } from "react-bootstrap";
import { escapeRegexCharacters } from "../components/AutoSuggestInput";
import moment from "moment";
import { CURRENCY_SYMBOL, DATE_FORMAT } from "./constant";
import Tooltip from "../components/Custom/Tooltip";
import { DOCUCMENTS, SEARCH_TABS } from "../constants/searchConstants";

export const fomattedCurrencyAmount = (amount, currency = CURRENCY_SYMBOL) => {
  return amount && amount > 0 ? `${currency} ${Number(amount).toLocaleString()}` : ""
}

export const getNumberOfPayments = (NumberOfPayments, frequency) => {
  return NumberOfPayments ? `${Number(NumberOfPayments).toFixed()} ${frequency}` : ""
}

export const getViewInAppURL = (opportunityId) => `${process.env.REACT_APP_MCA_BROKERAGE_APP_URL}/lightning/r/Opportunity/${opportunityId}/view`;

export const getDateRangeObject = ({ startDate, endDate }) => {
  const dateRange = { key: 'selection' }
  dateRange.startDate = startDate ? new Date(startDate) : null
  dateRange.endDate = endDate ? new Date(endDate) : null
  return dateRange
}

export const handleMultipleDetails = (
  field = [],
  dataObject,
  key,
  textColor,
  shouldCapitalize = false,
  shouldIncludeFirst = true
) => {
  return (
    <>
        <span className={`${textColor}`}>{getDataAndHighlightedText(dataObject, key)}</span>
        {field.length - 1 > 0 && (
          <Tooltip
            trigger="click"
            tooltipBody={
              <ListGroup className="gap-2">
                {!!field.length &&
                  field.map((item, i) => (
                    i === 0 ? 
                      shouldIncludeFirst ? 
                      <ListGroup.Item key={item} className={`border-0 text-primary p-0 fs-sm fw-normal lh-sm ${shouldCapitalize ? "text-capitalize" : ""}`}>
                        {item?.toLowerCase()}
                      </ListGroup.Item> 
                      : null 
                    : <ListGroup.Item key={item} className={`border-0 text-primary p-0 fs-sm fw-normal lh-sm ${shouldCapitalize ? "text-capitalize" : ""}`}>
                      {item?.toLowerCase()}
                      </ListGroup.Item>
                  ))}
              </ListGroup>
            }
          >
            <span className="bg-black bg-opacity-10 p-1 ms-1 rounded-1 fs-9 cursor-pointer text-nowrap" onClick={(e) => e.stopPropagation()}>
              +{field.length - 1}
            </span>
          </Tooltip>
        )}
    </>
  );
};

export function getSuggestions(
  value,
  list,
  seeAllResults = true,
  seeAllCategories = false,
  numberOfSuggestion = 4
) {
  const escapedValue = escapeRegexCharacters(value.trim());
  if (!escapedValue) return [];

  const regex = new RegExp("^" + escapedValue, "i");

  const result = list.slice(0, numberOfSuggestion);

  if (!!result.length && seeAllResults)
    result.push({ name: "See all results", id: "allResults" });
  if (!!result.length && seeAllCategories)
    result.push({ name: "See in all categories", id: "allCategories" });

  return result;
}

export const formattedTime = (date, dateFormat=DATE_FORMAT) => moment(date).format(dateFormat);

export function getDataAndHighlightedText(dataObject, key) {
  /**
     @param dataObject accept object
     @param key key is to access data from @dataObject
     @returns if dont have highlighted key in @dataObject will return direct value 'dataObject[key]' and if will get highlighted key then returning div with highlighted part.
   */

  if (
    dataObject.hasOwnProperty("@search.highlights") &&
    dataObject["@search.highlights"].hasOwnProperty(key)
  ) {
    const tagWithColor = dataObject["@search.highlights"][key][0];
    return (
      <div className="single-line-ellipsis cursor-pointer"
        dangerouslySetInnerHTML={{
          __html: tagWithColor,
        }}
      />
    );
  }
  return dataObject[key];
}

export function getFileType(documentUrl) {
  /**
     @param documentUrl accept document url/name
     @returns file's fileType/extension.
   */
  const splitedFile = documentUrl.split(".");
  return splitedFile[splitedFile.length - 1].toLowerCase();
}

export const merchantContactPhones = (data) => {
  return Array(
    ...new Set([
      data?.ContactPhone,
      data?.MerchantPhone,
      data?.Contact2Mobile,
      data?.Contact3Mobile,
      data?.Contact4Mobile,
      data?.Contact5Mobile,
    ])
  ).filter((item) => item);
}

export const merchantContactEmails = (data) => {
  return Array(
    ...new Set([
      data?.ContactEmail,
      data?.Contact2Email,
      data?.Contact3Email,
      data?.Contact4Email,
      data?.Contact5Email,
    ])
  ).filter((item) => item);
}

export const merchantContactNames = (data) => {
  return Array(
    ...new Set([
      data?.Contact,
      data?.Contact2,
      data?.Contact3,
      data?.Contact4,
      data?.Contact5,
    ])
  ).filter((item) => item);
}

export const fundedDealsSalesRepNames = (data) => {
  return Array(
    ...new Set([
      data?.salesRepOpenerName,
      data?.salesRepCloserName,
    ])
  ).filter((item) => item).map((item) => item === data?.salesRepCloserName ? `Closer: ${data?.salesRepCloserName}` : item);
}

export const maskSSN = (number) =>
  number && `XXX-XX-${number.slice(7, number?.length)}`;

export const tabsAsPerAccess = (showDocumentsFlag) => {
  if (!showDocumentsFlag) {
    return Object.entries(SEARCH_TABS).filter((item) => item[0] !== DOCUCMENTS);
  }
  return Object.entries(SEARCH_TABS);
};

export const searchQueryForEmails = (searchValue, merchantName, returnProperQueryWithOuterQoutes=true) => {
  const trimmedSearchValue = searchValue && searchValue?.replace(/\s+/g, " ").trim();

  const isSearchTermPhrase = /^".*"$/.test(trimmedSearchValue);
  const basicSearch = isSearchTermPhrase
    ? trimmedSearchValue
    : trimmedSearchValue?.split(" ").join(" OR ") || "";

  const searchQuery = merchantName
    ? `"${merchantName}${basicSearch && ` AND ${isSearchTermPhrase ? basicSearch.replace(/"/g, "") : `(${basicSearch})`}`}"`
    : isSearchTermPhrase ? basicSearch :`"${basicSearch}"`;
    
  return returnProperQueryWithOuterQoutes ? searchQuery : searchQuery.replace(/"/g, "")
};