import * as React from 'react'
const SearchIcon = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={20}
    height={20}
    fill='none'
    {...props}>
    <path
      stroke='#1C2120'
      strokeLinecap='round'
      strokeWidth={1.5}
      d='m17 17-3.15-3.15M15.6 9.3A6.3 6.3 0 1 1 3 9.3a6.3 6.3 0 0 1 12.6 0Z' />
  </svg>
)
export default SearchIcon
