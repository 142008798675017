import { memo } from "react";
import { useNavigate } from "react-router-dom";
import Autosuggest from "react-autosuggest";
import "./autoSuggest.scss";
import ArrowIcon from "../../images/ArrowIcon";

export function escapeRegexCharacters(str) {
  return str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
}

function getSuggestionValue(suggestion) {
  return suggestion.name;
}

function getHighlightedText(text, highlight) {
  if (!text) {
    return <></>;
  }

  const string = typeof text !== "string" ? text[0] : text;
  const parts = string.toLowerCase().split(new RegExp(`(${highlight})`, "gi"));
  return (
    <span className={""}>
      {" "}
      {!!parts.length &&
        parts.map((part, i) => (
          <span
            key={i}
            style={
              part?.toLowerCase() === highlight?.toLowerCase()
                ? { fontWeight: "bold" }
                : {}
            }
          >
            {part}
          </span>
        ))}{" "}
    </span>
  );
}

const AutoSuggestInput = ({
  placeholder = "Type...",
  seeAllResults = false,
  seeAllResultsNavigation = "/",
  seeAllCategories = false,
  seeAllCategoriesNavigation = "/",
  onChange = () => {},
  searchValue,
  onSuggestionsFetchRequested,
  onSuggestionsClearRequested,
  suggestions,
  className = "",
  onSuggestionSelected = ()=> {},
  onKeyDown = () => {},
}) => {
  const renderSuggestion = (suggestion) => {
    if (suggestion.id === "allResults" && seeAllResults) {
      return (
        <div className="text-primary fs-sm fw-bold d-flex align-items-center gap-1">
          <span>{suggestion.name}</span>
          <ArrowIcon />
        </div>
      );
    } else if (suggestion.id === "allCategories" && seeAllCategories) {
      return (
        <div className="text-primary fs-sm fw-bold">{suggestion.name}</div>
      );
    }
    return (
      <div className="text-capitalize">
        {getHighlightedText(suggestion.name, searchValue?.trim())}
      </div>
    );
  };

  return (
    <Autosuggest
      suggestions={suggestions}
      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
      onSuggestionsClearRequested={onSuggestionsClearRequested}
      getSuggestionValue={getSuggestionValue}
      renderSuggestion={renderSuggestion}
      inputProps={{
        placeholder: placeholder,
        value: searchValue,
        onChange: onChange,
        onKeyDown: onKeyDown,
      }}
      onSuggestionSelected={onSuggestionSelected}
    />
  );
};

export default memo(AutoSuggestInput);
