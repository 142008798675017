
import {SET_LEADS_DATA, SET_MERCHANT_LEADS_DATA, SET_LEADS_SELECTED_FILTERS, SET_MERCHANT_LEADS_SELECTED_FILTERS } from "./actions";

  let initialState = {
    leadsData: {},
    merchantLeadsData: {},
    leadsSelectedFilters: null,
    merchantLeadsSelectedFilters: null,
  };
  
  const leadReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
      case SET_LEADS_DATA:
        return { ...state, leadsData: payload };

      case SET_MERCHANT_LEADS_DATA:
        return { ...state, merchantLeadsData: payload };

      case SET_LEADS_SELECTED_FILTERS:        
        return { ...state, leadsSelectedFilters: payload };

      case SET_MERCHANT_LEADS_SELECTED_FILTERS:        
        return { ...state, merchantLeadsSelectedFilters: payload };

      default:
        return state;
    }
  };
  
  export default leadReducer;
  