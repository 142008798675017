import { getDocumentFilterString } from "../../../services/filterServices";
import axios from "../../../config/axiosInterceptor";
import { SALS_REP, OFF_SHORE_ADMIN } from "../../../utils/constant";
export const GET_DOCUMENT_SEARCH = "GET_DOCUMENT_SEARCH";
export const SELECT_MULTIPLE_DOCUMENTS = "SELECT_MULTIPLE_DOCUMENTS";
export const SELECTED_DOCUMENT_FILTERS = "SELECTED_DOCUMENT_FILTERS";
export const MERCHANT_SEARCH_KEY = "MERCHANT_SEARCH_KEY";
export const SET_MERCHANT_DOCUMENT_SELECTED_FILTERS = "SET_MERCHANT_DOCUMENT_SELECTED_FILTERS";

export const getDocumentsResults = (
  searchValues,
  tokenPromise,
  setShowDocFilter = () => {}
) =>
  async function (dispatch, getState) {
    const { documentReducer : { selectedDocumentFilters, merchantDocumentSelectedFilters }} = getState();
    let {
      searchValue,
      userRoles,
      skip = 0,
      perPage = 10,
      merchantName = false,
      DMMerchantId
    } = searchValues;
    let searchTerm = searchValue?.replace(/\s+/g, " ").trim().split(" ").join(" | ") || "";

    if (!!merchantName) {
      searchTerm = !!searchTerm ? `"${merchantName}" + (${searchTerm})` :  `"${merchantName}"`;
    }
    
    const API_URL = `/v2/docs?search=${encodeURIComponent(searchTerm)}&$select=AzureReferenceId,SourceDocumentName,DocumentTypeCode,DocumentType,SourceSystem,MerchantName,AzureMerchantId,DocumentName,DocumentURL,AzureUpdateDate,SourceReferenceId&$count=true&highlight=content&$orderby=AzureUpdateDate desc`;

    let finalURL = API_URL;  
    const documentFilter = DMMerchantId ? merchantDocumentSelectedFilters : selectedDocumentFilters;
        
      if (documentFilter) {
        const sourceSystemSelectedOptions =
          documentFilter?.sourceSystemSelectedOptions ||
          [];
        const documentTypeSelectedOptions =
          documentFilter?.documentTypeSelectedOptions ||
          [];
  
        finalURL = getDocumentFilterString(
          finalURL,
          userRoles,
          sourceSystemSelectedOptions,
          documentTypeSelectedOptions,
          documentFilter.startDate,
          documentFilter.endDate,
          DMMerchantId
        );
      } else {
        if (userRoles?.includes(SALS_REP)) {
          if(DMMerchantId){
            finalURL += `&$filter=AzureMerchantId eq '${DMMerchantId}' and ((SourceSystem eq 'OM' and DocumentTypeCode eq 'BNK_STMT') or (SourceSystem eq 'JF'))`;
          } else{
            finalURL += `&$filter=(SourceSystem eq 'OM' and DocumentTypeCode eq 'BNK_STMT') or (SourceSystem eq 'JF')`;
          }
        } else if (userRoles?.includes(OFF_SHORE_ADMIN)) {
          if(DMMerchantId){
            finalURL += `&$filter=AzureMerchantId eq '${DMMerchantId}' and (DocumentTypeCode eq 'BNK_STMT')`;
          } else{
            finalURL += `&$filter=(DocumentTypeCode eq 'BNK_STMT')`;
          }
        }else{
          if(DMMerchantId){
            finalURL += `&$filter=AzureMerchantId eq '${DMMerchantId}'`;
          }
        }
      }

    const accessToken = await tokenPromise;
    const axiosConfig = {
      url: finalURL,
      method: "get",
      params:{ $skip: skip, $top: perPage},
      headers: {
         Authorization: `Bearer ${accessToken}`,
      },
    };

    try {
      const response = await axios(axiosConfig);
      dispatch({
        type: GET_DOCUMENT_SEARCH,
        payload: {
          data: response.data,
          filterApplied: !!documentFilter
        },
      });
    } catch (error) {
      return error;
    } finally {
      !!documentFilter && setShowDocFilter(false);
    }
  };
