import {searchQueryForEmails} from "../utils/helpers"
const _ = require("lodash");

export const getEmailFiltersList = (emailValuesArray) => {
  if (!emailValuesArray) {
    return null;
  }

  let emailListArray = { from: {}, to: {} };

  emailValuesArray.forEach((element, index) => {
    const email = element?.from?.emailAddress?.address;

    emailListArray.from[email] = emailListArray.from[email]
      ? emailListArray.from[email] + 1
      : 1;

    const toList = element?.toRecipients;
    toList.forEach((entry) => {
      const emailAddress = entry?.emailAddress?.address;
      emailListArray.to[emailAddress] = emailListArray.to[emailAddress]
        ? emailListArray.to[emailAddress] + 1
        : 1;
    });
  });

  return emailListArray;
};

export const getMMDDYYYY = (dateObject) => {
  let date, month, year;

  date = dateObject.getDate();
  month = dateObject.getMonth() + 1;
  year = dateObject.getFullYear();

  date = date.toString().padStart(2, "0");

  month = month.toString().padStart(2, "0");

  return `${month}/${date}/${year}`;
};

export const getTimestamp = (dateObject) => {
  if (!dateObject) {
    return "";
  }

  let date, month, year;

  date = dateObject.getDate();
  month = dateObject.getMonth() + 1;
  year = dateObject.getFullYear();

  date = date.toString().padStart(2, "0");

  month = month.toString().padStart(2, "0");

  return `${year}-${month}-${date}T00:00:00.000Z`;
};

export const getAPIUrl = (
  searchValues,
  filters,
  delegatedEmailflag,
  delegatedEmailId
) => {
  let { searchValue, merchantName} = searchValues;  


  let searchFilterString = "";

  if (filters.from) {
    for (let i = 0; i < filters.from.length; i++) {
      if (filters.from[i].value !== "All") {
        searchFilterString += `from:${filters.from[i].value}, `;
      }
    }
  }

  if (filters.to) {
    for (let i = 0; i < filters.to.length; i++) {
      if (filters.to[i].value !== "All") {
        searchFilterString += `to:${filters.to[i].value}, `;
      }
    }
  }

  let dateFilter = "";

  if (filters.startDate) {
    dateFilter += `received>=${filters.startDate} AND `;
  }

  if (filters.endDate) {
    dateFilter += `received<=${filters.endDate} AND `;
  }

  let API_URL = `${process.env.REACT_APP_MICROSOFT_GRAPH_API_BASE_URL}/me/messages?$top=12&$search=${encodeURIComponent(`"${searchFilterString}${dateFilter}${searchQueryForEmails(searchValue, merchantName, false)}"`)}`;  
 
  if (delegatedEmailflag && delegatedEmailId) {
    API_URL = `${process.env.REACT_APP_MICROSOFT_GRAPH_API_BASE_URL}/users/${delegatedEmailId}/mailfolders('Inbox')/messages?$top=12&$search=${encodeURIComponent(`"${searchFilterString}${dateFilter}${searchQueryForEmails(searchValue, merchantName, false)}"`)}`;
  }

  return API_URL;
};

export const defaultFromState = [{ value: "All", label: "All",}];

export const defaultToState = [{value: "All",label: "All",}];

export const defaultDateRangeState = [{key: "selection"}];

export const isArrayEqual = (firstArray, secondArray) => {
  return _(firstArray).xorWith(secondArray, _.isEqual).isEmpty();
};

export const getConversations = (listOfMails) => {
  const conversations = {};
  for (let i = 0; i < listOfMails.length; i++) {
    const element = listOfMails[i];
    if (conversations[element.conversationId]) {
      conversations[element.conversationId].push(element);
    } else {
      conversations[element.conversationId] = [];
      conversations[element.conversationId].push(element);
    }
  }
  return conversations;
};

export function getCombineEmailList(delegatedMap, filteredResults) {
  let combinedEmailList =
    filteredResults?.length > 0 ? [...filteredResults] : [];
  if (!delegatedMap || Object.keys(delegatedMap).length < 1) {
    return combinedEmailList;
  }
  let emailIdList = Object.keys(delegatedMap);

  if (emailIdList?.length > 0) {
    for (let emailId of emailIdList) {
      const emailList = delegatedMap[emailId]?.value || [];
      if (emailList && emailList.length > 0) {
        for (let email of emailList) {
          email.delegatedEmailId = emailId;
        }
        combinedEmailList = [...combinedEmailList, ...emailList];
      }
    }
  }
  if (combinedEmailList.length > 0) {
    combinedEmailList.sort((x, y) => {
      let d1 = new Date(x.receivedDateTime).getTime();
      let d2 = new Date(y.receivedDateTime).getTime();

      if (d1 > d2) {
        return -1;
      }
      if (d1 < d2) {
        return 1;
      }
      return 0;
    });
  }
  return combinedEmailList;
}

export const getDelegatedConversationView = (
  delegatedMap,
  filteredResultList
) => {
  const combinedEmailList = getCombineEmailList(
    delegatedMap,
    filteredResultList
  );
  const conversations = {};
  if (combinedEmailList?.length > 0) {
    for (let i = 0; i < combinedEmailList.length; i++) {
      const element = combinedEmailList[i];
      if (conversations[element.conversationId]) {
        conversations[element.conversationId].push(element);
      } else {
        conversations[element.conversationId] = [];
        conversations[element.conversationId].push(element);
      }
    }
  }
  return conversations;
};

export const getDocumentFilterString = (
  finalURL,
  userRoles,
  sourceSystemSelectedOptions,
  documentTypeSelectedOptions,
  startDate,
  endDate,
  DMMerchantId
) => {
  let sourceSystemFilterString = "(";
  let documentTypeFilterString = "";

  if (sourceSystemSelectedOptions.length > 0) {
    sourceSystemSelectedOptions.forEach((element, index) => {
      if (index === 0) {
        sourceSystemFilterString += `SourceSystem eq '${element.value}'`;
      } else {
        sourceSystemFilterString += ` or SourceSystem eq '${element.value}'`;
      }
    });
  } else {
    sourceSystemFilterString = "";
  }

  if (sourceSystemFilterString) {
    sourceSystemFilterString += ")";
    documentTypeFilterString = " and (";
  } else {
    documentTypeFilterString = "(";
  }

  if (documentTypeSelectedOptions.length > 0) {
    documentTypeSelectedOptions.forEach((element, index) => {
      if (index === 0) {
        documentTypeFilterString += `DocumentTypeCode eq '${element.value}'`;
      } else {
        documentTypeFilterString += ` or DocumentTypeCode eq '${element.value}'`;
      }
    });
  } else {
    documentTypeFilterString = "";
  }

  if (documentTypeFilterString) {
    documentTypeFilterString += ")";
  }

  let startDateString = "";
  let endDateString = "";
  let dateFilterString = "";

  if (startDate) {
    startDateString = `AzureUpdateDate ge ${startDate}`;
  }

  if (endDate) {
    endDateString = `AzureUpdateDate le ${endDate}`;
  }

  if(startDateString && endDateString){
    dateFilterString = `${startDateString} and ${endDateString}`;
  } else if(startDateString && !endDateString){
    dateFilterString = startDateString;
  } else if(endDateString && !startDateString){
    dateFilterString += `${endDateString}`;
  }

  // if (startDateString) {
  //   dateFilterString = startDateString;
  // }

  // if (dateFilterString) {
  //   dateFilterString += ` and ${endDateString}`;
  // } else {
  //   dateFilterString += `${endDateString}`;
  // }

  if (sourceSystemFilterString || documentTypeFilterString) {
    finalURL += `&$filter=(${sourceSystemFilterString}${documentTypeFilterString})`;

    if (dateFilterString) {
      finalURL += ` and (${dateFilterString})`;
    }
  } else {
    if (dateFilterString) {
      finalURL += `&$filter=(${dateFilterString})`;
    }
  }
  
  if (userRoles?.includes("sales-rep")) {
    if (finalURL.includes("&$filter=")) {
      finalURL += ` and ((SourceSystem eq 'OM' and DocumentTypeCode eq 'BNK_STMT') or (SourceSystem eq 'JF'))`;
    } else {
      finalURL += `&$filter=((SourceSystem eq 'OM' and DocumentTypeCode eq 'BNK_STMT') or (SourceSystem eq 'JF'))`;
    }
  }

  if (userRoles?.includes("off-shore-admin")) {
    if (finalURL.includes("&$filter=")) {
      finalURL += ` and (DocumentTypeCode eq 'BNK_STMT')`;
    } else {
      finalURL += `&$filter=(DocumentTypeCode eq 'BNK_STMT')`;
    }
  }

  return DMMerchantId ? `${finalURL} and AzureMerchantId eq '${DMMerchantId}'`: finalURL;
};
