import { combineReducers } from "redux";
import { searchReducer } from "../components/Search/reducer";
import { merchantReducer } from "../components/Search/merchantReducer";
import { profileReducer } from "../pages/HomePage/reducer";
import { filterReducer } from "../components/SearchFilters/reducer";
import { documentReducer } from "../Layouts/SearchResults/DocumentSearchResult/reducer";
import { loadingReducer } from "../components/Loading/reducer";
import adminReducer from "../Layouts/SearchResults/AdminResults/reducer";
import leadsReducer from "./leads/reducer";
import offerReducer from "./offers/reducer";
import fundedDealsReducer from "./fundedDeals/reducer";
import opportunitiesReducer from "./opportunities/reducer";
import bookmarksReducer from "./bookmarks/reducer"

const rootReducer = combineReducers({
  profileReducer,
  searchReducer,
  filterReducer,
  documentReducer,
  merchantReducer,
  loadingReducer,
  adminReducer,
  offerReducer,
  leadsReducer,
  fundedDealsReducer,
  opportunitiesReducer,
  bookmarksReducer
});

export default rootReducer;
