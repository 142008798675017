import * as React from "react"
const DollerIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={56}
    height={56}
    fill="none"
    {...props}
  >
    <rect width={56} height={56} fill="#1C2120" rx={28} />
    <path
      fill="#FDFDFD"
      fillRule="evenodd"
      d="M28 17a1 1 0 0 1 1 1v1a5 5 0 0 1 5 5 1 1 0 1 1-2 0 3 3 0 0 0-3-3v6a5 5 0 0 1 0 10v1a1 1 0 1 1-2 0v-1a5 5 0 0 1-5-5 1 1 0 1 1 2 0 3 3 0 0 0 3 3v-6a5 5 0 0 1 0-10v-1a1 1 0 0 1 1-1Zm1 12v6a3 3 0 1 0 0-6Zm-2-2v-6a3 3 0 1 0 0 6Z"
      clipRule="evenodd"
    />
  </svg>
)
export default DollerIcon;
