export const categoryConstants = {
  all: "all",
  email: "email",
  salesforce: "salesforce",
  document: "document",
  other: "other",
  admin: "admin",
  leads: "leads",
};

export const adminFiltersTab = {
  new: "New",
  inProgress: "In Progress",
  forMyReview: "Pending Review",
  close: "Closed",
};

export const NEW_TAB = "new";

export const NEW_TAB_TABLE_HEADER = [
  "Actions",
  "Merchant",
  "Contact",
  "Lead Source Status",
  "Lead Created Date",
];
export const TABLE_HEADER = [
  "Actions",
  "Merchant",
  "Contact",
  "Updated Date",
  "AATM Record Status",
  "Assigned to",
  "Lead Created Date",
];

export const ADMIN_TABS = {
  NEW_TAB: "new",
  IN_PROGRESS_TAB: "inProgress",
  REVIEW_TAB: "forMyReview",
  CLOSE_TAB: "close",
};

export const workflowActions = {
  adminCheckedOut: "CO",
  adminReview: "UR",
  adminNew: "NEW",
  adminCancel: "CANCEL",
  adminSubmit: "SUBMITTED",
  reviewerNew: "NEW_AR",
  reviewerCheckedOut: "CO_AR",
  reviewerReview: "AR",
  reviewerCancel: "CANCEL_AR",
  reviewerSubmit: "APPROVED",
};

export const leadSourceStatusOptionsList = [
  {
    value: 'All',
    label: 'All'
  },
  ...Object.keys(workflowActions).map(key=> {return { value: workflowActions[key], label: workflowActions[key]}})
];
export const initialAdminFilterFieldValue={
  leadCreatedDate: [
    {
      key: "selection",
    },
    
  ],
  keyword: "",
  leadSourceStatus: [
    {
      value: "All",
      label: "All",
      
    },
  ],
  checkedOutBy: ""
};
export const filterFieldOptionAsPerTab= new Map([
  [
    "keyword",
    [
      "new",
      "inProgress",
      "forMyReview"
    ]
  ],
  [
    "leadCreatedDate",
    [
      "new",
      "inProgress",
      "forMyReview"
    ]
  ],
  [
    "leadSourceStatus",
    [
      "new",
      "inProgress"
    ]
  ],
  [
    "checkedOutBy",
    [
      "inProgress"
    ]
  ]
])

// export const MAP_STATUS_AS_PER_TAB = new Map([
// ["new", `${workflowActions.adminNew},${workflowActions.adminCancel}`],
// ["inProgress", `${workflowActions.adminSubmit},${workflowActions.adminCheckedOut},${workflowActions.adminReview},${workflowActions.reviewerReview},${workflowActions.reviewerCheckedOut}`],
// ["forMyReview", `${workflowActions.reviewerNew},${workflowActions.reviewerCancel}`],
// ["close", workflowActions.reviewerSubmit]
// ])

