import * as React from "react"
const CheckMarkIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={56}
    height={56}
    fill="none"
    {...props}
  >
    <rect width={56} height={56} fill="#1C2120" rx={28} />
    <path
      fill="#FDFDFD"
      fillRule="evenodd"
      d="M28 19a9 9 0 1 0 0 18 9 9 0 0 0 0-18Zm-11 9c0-6.075 4.925-11 11-11s11 4.925 11 11-4.925 11-11 11-11-4.925-11-11Zm15.74-2.673a1 1 0 0 1-.067 1.413l-5.164 4.694a1.5 1.5 0 0 1-2.018 0l-2.164-1.967a1 1 0 0 1 1.346-1.48L26.5 29.65l4.827-4.389a1 1 0 0 1 1.413.067Zm-5.904 4.627Z"
      clipRule="evenodd"
    />
  </svg>
)
export default CheckMarkIcon;
