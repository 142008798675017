import axios from "../config/axiosInterceptor";
import { OFFERS_URL } from "../utils/apiEndpoints";

export const fetchOffersAPI = async (searchTerm, tokenPromise) => {
  const { skip, perPage, searchValue, filterKeys, merchantId, expandedOfferFilterKey='' } = searchTerm;  

  let params = {
    $count: true,
    $skip: skip,
    $top: perPage,
    highlight: "dealName,funderName",
    highlightPreTag: "<span class=bg-yellow-light>",
    highlightPostTag: "</span>",
    $orderby: "submitted desc"
  };

  if (searchValue) params.search = searchValue;
  if (expandedOfferFilterKey) params.$filter = expandedOfferFilterKey;
  if (filterKeys) {
    params.$filter = getOffersFilterString({...filterKeys,merchantId: merchantId});
  } else {
    if (merchantId) params.$filter = `merchantId eq '${merchantId}'`;
  }

  const accessToken = await tokenPromise;
  return await axios({
    url: OFFERS_URL,
    method: "GET",
    params,
    headers: { Authorization: `Bearer ${accessToken}` },
  });
};

export const getOffersFilterString = ({
  dealSelectOptions,
  funderSelectOptions,
  statusSelectedOptions,
  offerStatusSelectedOptions,
  offerSelectedOptions,
  startDate,
  endDate,
  merchantId,
}) => {
  let finalURL = "";

  const optionsKeyMap = {
    dealSelectOptions: { key: "dealName", value: dealSelectOptions },
    funderSelectOptions: { key: "funderName", value: funderSelectOptions },
    statusSelectedOptions: { key: "status", value: statusSelectedOptions },
    offerStatusSelectedOptions: {
      key: "offerStatus",
      value: offerStatusSelectedOptions,
    },
    offerSelectedOptions: { key: "offerSelected", value: offerSelectedOptions },
  };

  let test = "";

  Object.keys(optionsKeyMap).forEach((optionList) => {
    const list = optionsKeyMap[optionList].value;
    if (list && list.length > 0) {
      test += "(";
      list.forEach((option, index) => {
        if (index === 0) {
          test += `${optionsKeyMap[optionList].key} eq '${option.value}'`;
        } else {
          test += `or ${optionsKeyMap[optionList].key} eq '${option.value}'`;
        }
        if (index === optionList.length - 1) {
          test += `) and'`;
        }
      });
      test += ") and ";
    }
  });

  if (test.length > 0) {
    const a = test.lastIndexOf("and");
    test = test.substring(0, a - 1);
  }

  let startDateString = "";
  let endDateString = "";
  let dateFilterString = "";

  if (startDate) {
    startDateString = `submitted ge ${startDate}`;
  }

  if (endDate) {
    endDateString = `submitted le ${endDate}`;
  }

  if(startDateString && endDateString){
    dateFilterString = `${startDateString} and ${endDateString}`;
  } else if(startDateString && !endDateString){
    dateFilterString = startDateString;
  } else if(endDateString && !startDateString){
    dateFilterString += `${endDateString}`;
  }

  if (test) {
    finalURL += `(${test})`;
    if (dateFilterString) {
      finalURL += ` and (${dateFilterString})`;
    }
  } else {
    if (dateFilterString) {
      finalURL += `(${dateFilterString})`;
    }
  }

  // if (userRoles?.includes("sales-rep")) {
  //   if (finalURL.includes("")) {
  //     finalURL += ` and ((SourceSystem eq 'OM' and DocumentTypeCode eq 'BNK_STMT') or (SourceSystem eq 'JF'))`;
  //   } else {
  //     finalURL += `((SourceSystem eq 'OM' and DocumentTypeCode eq 'BNK_STMT') or (SourceSystem eq 'JF'))`;
  //   }
  // }
  return merchantId
    ? `merchantId eq '${merchantId}' and ${finalURL}`
    : finalURL;
};
