import * as React from "react";

const Logo = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={63}
    height={14}
    fill="none"
    {...props}
  >
    <path
      fill="#388173"
      d="M9.58 11.527H4.383L3.55 14H0L5.038 0h3.927l5.037 14h-3.59l-.832-2.473Zm-.873-2.632L6.98 3.769 5.276 8.895h3.43ZM25.988 11.527h-5.196L19.959 14h-3.55l5.037-14h3.927l5.038 14h-3.59l-.833-2.473Zm-.873-2.632L23.39 3.769l-1.706 5.126h3.431ZM43.745 0v2.732h-3.689V14h-3.391V2.732h-3.69V0h10.77ZM63 0v14h-3.392V5.604L56.495 14h-2.737l-3.134-8.416V14h-3.392V0h4.007l3.907 9.692L59.013 0H63Z"
    />
  </svg>
);
export default Logo;
