import { getAPIUrl } from '../../services/filterServices';
import { ADD_SEARCH_TYPE, GET_FILTERED_DELEGATED_EMAILS_FOR_ALL_ID, GET_FILTERED_RESULTS } from '../SearchFilters/action';
import { hideLoading, showLoading } from '../Loading/action';
import axios from "../../config/axiosInterceptor"
import { searchQueryForEmails } from '../../utils/helpers';

export const GET_SEARCH_RESULT = 'GET_SEARCH_RESULT';
export const LOAD_MORE_SEARCH_RESULT = 'LOAD_MORE_SEARCH_RESULT';

export const GET_MERCHANT_DATA_SUCCESS = 'GET_MERCHANT_DATA_SUCCESS';
export const GET_MERCHANT_DATA_FAILURE = 'GET_MERCHANT_DATA_FAILURE';
export const GET_MERCHANT_DATA_LOADING = 'GET_MERCHANT_DATA_LOADING';

export const GET_DELEGATED_EMAILS_FOR_SELECTED_ID = "GET_DELEGATED_EMAILS_FOR_SELECTED_ID";
export const GET_DELEGATED_EMAILS_FOR_ALL_ID = "GET_DELEGATED_EMAILS_FOR_ALL_ID"
export const REMOVE_DELEGATED_EMAIL_LIST = "REMOVE_DELEGATED_EMAIL_LIST";
export const SELF_DELEGATED_EMAIL_LIST = "SELF_DELEGATED_EMAIL_LIST";
export const GET_DELEGATED_EMAIL_IDS_FOR_DROPDOWN = "GET_DELEGATED_EMAIL_IDS_FOR_DROPDOWN";


export const getSearchResults = (searchValues, tokenPromise) => function (dispatch, getState) {
    const { filterReducer : { merchantEmailSelectedFilters, emailSelectedFilters }} = getState();
    let { searchValue, perPage = 12, merchantName=false } = searchValues;
    const filterObject = merchantName ? merchantEmailSelectedFilters : emailSelectedFilters;
    
    let API_OR_URL = `${process.env.REACT_APP_MICROSOFT_GRAPH_API_BASE_URL}/me/messages`; 
    let params = {};

    if(filterObject){
        API_OR_URL = getAPIUrl(searchValues, filterObject);
    } else {
        params = { $top: perPage, $search: `${searchQueryForEmails(searchValue, merchantName)}`}
    }

    dispatch({
        type: ADD_SEARCH_TYPE,
        payload: {
            type: 'API_OR_URL',
            keyword: searchValue,
        }
    });

    dispatch(showLoading());

    tokenPromise.then((accessToken) => {
        const axiosConfig = {
            method: 'GET',
            url: API_OR_URL,
            params,
            headers: {
                'Authorization': `Bearer ${accessToken}`
            },
        };

        axios(axiosConfig)
            .then((response) => {
                dispatch({
                    type: GET_SEARCH_RESULT,
                    payload: response
                });
                dispatch({
                    type: GET_FILTERED_RESULTS,
                    payload: {
                        response,
                        filterApplied: false
                    }
                });
                dispatch(hideLoading());
            })
            .catch((error) => console.log("error = ", error));

    })
        .catch((error) => console.log(error));
        dispatch(hideLoading());
}

export const getFilteredEmailResults = (searchValues, tokenPromise, setShowEmailFilter) => function (dispatch,getState) {
    const { filterReducer : { merchantEmailSelectedFilters, emailSelectedFilters }} = getState();
    const filterObject = searchValues?.merchantName ? merchantEmailSelectedFilters : emailSelectedFilters;
    
    const API_URL = getAPIUrl(searchValues, filterObject);
    dispatch(showLoading());
    tokenPromise.then((accessToken) => {
        const axiosConfig = {
            method: 'GET',
            url: API_URL,
            headers: {
                'Authorization': `Bearer ${accessToken}`
            },
        };

        axios(axiosConfig)
            .then((response) => {
                const updatedResponse  = filterObject.hasAttachments ? {...response, data : {...response.data, value: response.data.value.filter(dt => dt.hasAttachments)}}: response;                
                dispatch({
                    type: GET_FILTERED_RESULTS,
                    payload: {
                        response: updatedResponse,
                        filterApplied: !!filterObject,
                        filters: filterObject
                    }
                });
                setShowEmailFilter(false)
                dispatch(hideLoading());
            })
            .catch((error) => console.log("error = ", error));

    })
        .catch((error) => console.log(error));
        dispatch(hideLoading());
    return true
};

export const getFilteredDelegatedEmails = (
  searchValues,
  delegatedEmailIdList,
  tokenPromise,
  setShowEmailFilter
) =>
  async function (dispatch, getState) {    

    const { filterReducer : { merchantEmailSelectedFilters, emailSelectedFilters }} = getState();
    const filterObject = searchValues?.merchantName ? merchantEmailSelectedFilters : emailSelectedFilters;
    
    let delegatedEmailIdMap = {};
    if (!delegatedEmailIdList) {
      setShowEmailFilter(false);
      return;
    }

    dispatch(showLoading());

    for (const idObject of delegatedEmailIdList.value) {
      const id = idObject.toLowerCase();
      const API_URL = getAPIUrl(searchValues, filterObject, true, id);

      const accessToken = await tokenPromise;

      const axiosConfig = {
        method: "GET",
        url: API_URL,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      };

      let response = {
        data: {
          "@odata.context": "",
          value: [],
        },
      };

      try {
        response = await axios(axiosConfig);
        response = filterObject.hasAttachments ? {...response, data:{...response.data, value:response.data.value.filter(dt => dt.hasAttachments)}} : response
        delegatedEmailIdMap[id] = response?.data;
        // dispatch({
        //   type: GET_DELEGATED_EMAILS_FOR_ALL_ID,
        //   payload: delegatedEmailIdMap,
        // });
        dispatch({
          type: GET_FILTERED_DELEGATED_EMAILS_FOR_ALL_ID,
          payload: delegatedEmailIdMap,
        });
      } catch (error) {
        console.log("getFilteredDelegatedEmails error >> ", error);
      }
    }
    dispatch(hideLoading());
    setShowEmailFilter(false);
  };

export const getMerchantResults = (searchValues, tokenPromise, setShowMerchantLookupModal=()=>{}) => function (dispatch) {
    let { searchValue, option, skip = 0, perPage = 10} = searchValues;
    searchValue = searchValue?.replace(/\s+/g, ' ').trim();
    let words = searchValue?.split(' ');
    let andKeyword = encodeURIComponent(words?.join("+"));
    
    const API_OR_URL = `/merchants?search=${searchValue}&$count=true&highlight=Merchant,FederalTaxId,MerchantDBA,MerchantAddress,MerchantCity,MerchantState,MerchantZip,MerchantPhone,MerchantEmail,Contact,ContactEmail,ContactAddress,ContactCity,ContactState,ContactZip,ContactPhone`;
    const API_AND_URL = `/merchants?search=${andKeyword}&$count=true&highlight=Merchant,FederalTaxId,MerchantDBA,MerchantAddress,MerchantCity,MerchantState,MerchantZip,MerchantPhone,MerchantEmail,Contact,ContactEmail,ContactAddress,ContactCity,ContactState,ContactZip,ContactPhone`;
    const API_FULL_STRING_URL = `/merchants?search="${searchValue}"&$count=true&highlight=Merchant,FederalTaxId,MerchantDBA,MerchantAddress,MerchantCity,MerchantState,MerchantZip,MerchantPhone,MerchantEmail,Contact,ContactEmail,ContactAddress,ContactCity,ContactState,ContactZip,ContactPhone`;

    let apis = {
        API_AND_URL,
        API_FULL_STRING_URL
    }

    dispatch({
        type: GET_MERCHANT_DATA_LOADING
    });
    dispatch(showLoading());

    tokenPromise.then((accessToken) => {
        const axiosConfig = {
            method: 'GET',
            url: apis[option] || API_OR_URL,
            params:{'$skip': skip, '$top': perPage, highlightPreTag: "<span class=bg-yellow-light>", highlightPostTag: "</span>"},
            headers: {
                  'Authorization': `Bearer ${accessToken}`
            },
        };

        axios(axiosConfig)
            .then((response) => {
                dispatch({
                    type: GET_MERCHANT_DATA_SUCCESS,
                    payload: response.data
                });
                setShowMerchantLookupModal(true);
                dispatch(hideLoading());
            })
            .catch((error) => {
                console.log("error = ", error)
                dispatch({
                    type: GET_MERCHANT_DATA_FAILURE,
                    payload: error
                });
                dispatch(hideLoading());
            });

    })
        .catch((error) => {
            console.log("error = ", error)
            dispatch({
                type: GET_MERCHANT_DATA_FAILURE,
                payload: error
            });
            dispatch(hideLoading());
        });
};

export const getDelegatedEmailListForSelectedId = (id, tokenPromise, searchValues, setDelegatedTitle) =>
	function (dispatch, getState) {
        const { filterReducer : { merchantEmailSelectedFilters, emailSelectedFilters }} = getState();
        
        const { searchValue, merchantName} = searchValues; 
        const filterObject = merchantName ? merchantEmailSelectedFilters : emailSelectedFilters;
        let API_URL = `${process.env.REACT_APP_MICROSOFT_GRAPH_API_BASE_URL}/users/${id}/mailfolders('Inbox')/messages`;
        let params={};

        if(filterObject){
            API_URL = getAPIUrl(searchValues, filterObject, true, id);
        } else {
            params = { $search:`${searchQueryForEmails(searchValue, merchantName)}`}
        }

        dispatch(showLoading());
        tokenPromise
			.then((accessToken) => {
				
				const axiosConfig = {
					method: "GET",
					url: API_URL,
                    params,
					headers: {
						Authorization: `Bearer ${accessToken}`,
					},
				};
				axios(axiosConfig)
					.then((response) => {
                        const payload = {list:response.data, id: id};

                        dispatch({
                            type: GET_DELEGATED_EMAILS_FOR_SELECTED_ID,
                            payload: payload, 
                        });
    
                        setDelegatedTitle(id);
                        dispatch(hideLoading());
                    })
					.catch((error) => {
						console.log("getDelegatedEmailListForSelectedId api failed = ", error);
                        dispatch(hideLoading());
					});
			})
			.catch((error) => {
				console.log("auth error = ", error);
                dispatch(hideLoading());
			});
	};

export const removeDelegatedEmailList = () => function (dispatch) {
    dispatch({
        type: REMOVE_DELEGATED_EMAIL_LIST,
    });
};

export const getCombinedSearchResuts = (searchValues, delegatedEmailIdList, tokenPromise) => async function (dispatch, getState) {
    const { filterReducer : { merchantEmailSelectedFilters, emailSelectedFilters }} = getState();
    let { searchValue, perPage = 12, merchantName=false} = searchValues; 
    let delegatedEmailIdMap = {};
    const filterObject = merchantName ? merchantEmailSelectedFilters : emailSelectedFilters;
    dispatch(showLoading());


    for (const idOBj of delegatedEmailIdList.value.filter(data => data !== 'NA')) {
        const id = idOBj.toLowerCase();
        let API_OR_URL = `${process.env.REACT_APP_MICROSOFT_GRAPH_API_BASE_URL}/users/${id}/mailfolders('Inbox')/messages`;

        let params = {};

        if(filterObject){
            API_OR_URL =  getAPIUrl(searchValues, filterObject, true, id);
        } else {
            params = { $top: perPage, $search:`${searchQueryForEmails(searchValue, merchantName)}`}
        }
    
        const accessToken = await tokenPromise;
				const axiosConfig = {
					method: "GET",
					url: API_OR_URL,
                    params,
					headers: {
						Authorization: `Bearer ${accessToken}`,
					},
				};
                let response = {data: {
                    "@odata.context": "",
                    value: [],
                }};
                
                try {
                    response = await axios(axiosConfig);

                }catch(error) {
                    console.log("inside getCombinedSearchResuts, actual error = ", error);
                }
				delegatedEmailIdMap[id] = response.data;
    } 
    dispatch({
        type: GET_DELEGATED_EMAILS_FOR_ALL_ID,
        payload: delegatedEmailIdMap
    });

    dispatch({
        type: GET_FILTERED_DELEGATED_EMAILS_FOR_ALL_ID,
        payload: delegatedEmailIdMap
    });

    dispatch(hideLoading());

}