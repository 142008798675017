export const listOfBackgroundColors = [
	"#43ABC9",
	"#B5C689",
	"#F58B4C",
	"#CD594A",
	"#107896",
	"#BCA136",
	"#093145",
	"#61897b",
	"#41499f",
	"#8d7d2b",
];

export const toastConfig = {
	position: "top-center",
	autoClose: 2000,
	hideProgressBar: true,
	closeOnClick: true,
	pauseOnHover: true,
	draggable: true,
	progress: undefined,
	theme: "dark",
	className: "fs-7",
	style: { width: "463px", margin: "3px auto", minHeight: "0" },
	// style: { width: "fit-content", margin: "3px auto", minHeight: "0" },
	limit:1
};