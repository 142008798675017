import { LEADS_API_URL } from "../utils/apiEndpoints";
import axios from "../config/axiosInterceptor"

// fetchLeadsAPI => API call for getting lead list as per searchValues and filter

export const fetchLeadsAPI = async (searchTerm, tokenPromise) => {
  const { skip, perPage, searchValue, filterkeys = {} } = searchTerm;

  let params = { $count: true, $skip: skip, $top: perPage, $orderby: "submissionAt desc", highlight: "merchant, email, contact", highlightPreTag: "<span class=bg-yellow-light>",highlightPostTag: "</span>",};

  if (searchValue) params.search = searchValue;
  if (filterkeys) {
        params = {...params, $filter:filterkeys}
    }
  const accessToken = await tokenPromise;
  return await axios({
    url: LEADS_API_URL,
    method: "GET",
    params,
    headers: { Authorization: `Bearer ${accessToken}` },
  });
};

export const getLeadsFilterString = ({
  leadTypeSelectedOption,
  startDate,
  endDate,
}) => {
  // existing code 
  let finalURL = "";

  const optionsKeyMap = {
    leadTypeSelectedOption: { key: "leadType", value: leadTypeSelectedOption },
  };

  let test = "";

  Object.keys(optionsKeyMap).forEach((optionList) => {
    const list = optionsKeyMap[optionList].value;
    if (list.length > 0) {
      test += "(";
      list.forEach((option, index) => {
        if (index === 0) {
          test += `${optionsKeyMap[optionList].key} eq '${option.value}'`;
        } else {
          test += `or ${optionsKeyMap[optionList].key} eq '${option.value}'`;
        }
        if (index === optionList.length - 1) {
          test += `) and'`;
        }
      });
      test += ") and ";
    }
  });

  if (test.length > 0) {
    const a = test.lastIndexOf("and");
    test = test.substring(0, a - 1);
  }

  let startDateString = "";
  let endDateString = "";
  let dateFilterString = "";

  if (startDate) {
    startDateString = `submissionAt ge ${startDate}`;
  }

  if (endDate) {
    endDateString = `submissionAt le ${endDate}`;
  }

  if(startDateString && endDateString){
    dateFilterString = `${startDateString} and ${endDateString}`;
  } else if(startDateString && !endDateString){
    dateFilterString = startDateString;
  } else if(endDateString && !startDateString){
    dateFilterString += `${endDateString}`;
  }

  if (test) {
    finalURL += `(${test})`;
    if (dateFilterString) {
      finalURL += ` and (${dateFilterString})`;
    }
  } else {
    if (dateFilterString) {
      finalURL += `(${dateFilterString})`;
    }
  }
  return finalURL;
};

