import React from "react";

const FallbackLoader = () => {
	return (
		<h1
			className="d-flex justify-content-center align-items-center text-primary"
			style={{ height: "80vh" }}
		>
			Loading...
		</h1>
	);
};

export default FallbackLoader;
