import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { AuthenticatedTemplate, useMsal } from "@azure/msal-react";
import {
  Button,
  Container,
  NavDropdown,
  Navbar,
} from "react-bootstrap";
import Logo from "../images/Logo";
import BookmarkIcon from "../images/BookmarkIcon";
import NotificationIcon from "../images/NotificationIcon";
import AvtarIcon from "../images/AvtarIcon";
import LogoutIcon from "../images/logoutIcon.png";
import GlobalSearchInput from "../components/GlobalSearchInput";
import { ROLES_DETAILS_MAPPER } from "../utils/constant";

function Header() {
  const { instance } = useMsal();
  const profileReducerState = useSelector((state) => state.profileReducer);
  const {pathname} = useLocation();  
  const userRoles = profileReducerState?.profile?.decoded?.roles || [];
  
  const handleLogout = (logoutType = "redirect") => {
    localStorage.removeItem("accessToken");
    if (logoutType === "popup") {
      instance.logoutPopup({
        postLogoutRedirectUri: "/",
        mainWindowRedirectUri: "/",
      });
      return;
    }
    instance.logoutRedirect({
      postLogoutRedirectUri: "/",
    });
  };

  return (
    <Navbar expand="lg" className="pt-4">
      <Container>
        <div>
          <Link to="/">
            <Logo />
          </Link>
        </div>
        <AuthenticatedTemplate>
          {pathname.includes("/search") ? <GlobalSearchInput /> : null}
          <div className="d-flex gap-4 align-items-center flex-shrink-0">
            {pathname !== "/bookmark" ? <Link
              to="/bookmark"
              className="rounded-circle notification-btn btn shadow-none"
              style={{ padding: "10px 5px" }}
            >
              <BookmarkIcon />
            </Link> : null}
            <Button
              className="rounded-circle notification-btn shadow-none"
              variant="transparent"
              style={{ padding: "10px 5px" }}
            >
              <NotificationIcon />
            </Button>
            <NavDropdown
              title={<AvtarIcon />}
              id="basic-nav-dropdown"
              className="profile-dropdown"
            >
              <NavDropdown.Item className="d-flex gap-2 mb-6">
                <AvtarIcon />
                <div className="d-flex flex-column">
                  <div className="fw-bold">
                    {profileReducerState?.profile?.displayName}
                  </div>
                  <div className="fs-8">
                    {profileReducerState?.profile?.mail}
                  </div>
                </div>
              </NavDropdown.Item>
              {userRoles?.map((role, roleIndex) => (
                <NavDropdown.Item className="p-2" key={`${role}-${roleIndex}`}>
                  {ROLES_DETAILS_MAPPER.get(role)?.title}{" "}
                </NavDropdown.Item>
              ))}
              <Button
                onClick={handleLogout}
                variant="tranparent"
                className="mt-1 shadow-none w-100"
                style={{ color: "#388173" }}
              >
                <>
                  <img src={LogoutIcon} alt="icon" /> Logout
                </>
              </Button>
            </NavDropdown>
          </div>
        </AuthenticatedTemplate>
      </Container>
    </Navbar>
  );
}

export default Header;
