import * as React from "react";
const NotificationIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={20}
    fill="none"
    {...props}
  >
    <path
      stroke="#1C2120"
      strokeLinecap="round"
      strokeWidth={1.5}
      d="M10.79 17.515a4.842 4.842 0 0 1-5.579 0M2.117 7.79c0-3.418 2.635-6.19 5.884-6.19 3.25 0 5.883 2.772 5.883 6.19v2.28c0 1.396.393 2.761 1.13 3.923l.116.183c.186.294-.014.688-.35.688H1.222c-.336 0-.536-.394-.35-.688l.116-.183a7.339 7.339 0 0 0 1.13-3.924V7.79Z"
    />
  </svg>
);
export default NotificationIcon;
