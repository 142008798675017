import { HIDE_LOADING, SHOW_LOADING } from "./action";

const initialState = { show: false };
export const loadingReducer = (state = initialState, action) => {
  const { type } = action;

  switch (type) {
    case SHOW_LOADING:
      return {
        ...state,
        show: true,
      };
    case HIDE_LOADING:
      return {
        ...state,
        show: false,
      };
    default:
      return state;
  }
};
