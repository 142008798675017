import {
    SET_OPPORTUNITIES_DATA,
    SET_MERCHANTS_OPPORTUNITIES_DATA,
    SET_OPPORTUNITIES_FILTERS,
    SET_MERCHANT_OPPORTUNITIES_FILTERS,
  } from "./action";
  
  let initialState = {
    opportunitiesData: {},
    merchantOpportunitiesData: {},
    opportunitiesFilters: null,
    merchantOpportunitiesFilters: null,
  };
  
  const opportunitiesReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
      case SET_OPPORTUNITIES_DATA:
        return { ...state, opportunitiesData: payload };
  
      case SET_MERCHANTS_OPPORTUNITIES_DATA:
        return { ...state, merchantOpportunitiesData: payload };

      case SET_OPPORTUNITIES_FILTERS:
        return { ...state, opportunitiesFilters: payload };

      case SET_MERCHANT_OPPORTUNITIES_FILTERS:
        return { ...state, merchantOpportunitiesFilters: payload };
  
      default:
        return state;
    }
  };
  
  export default opportunitiesReducer;
  