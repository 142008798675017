import {FAILED_GET_DELEGATED_EMAIL_IDS_FOR_DROPDOWN, GET_DELEGATED_EMAIL_IDS_FOR_DROPDOWN} from "./action"
export const UPDATE_PROFILE_DATA = 'UPDATE_PROFILE_DATA';
const initialInformationState = {};

export const profileReducer = (state = initialInformationState, action) => {
    const { type, payload } = action;

    switch (type) {
		case UPDATE_PROFILE_DATA:
			return {
				...state,
				profile: payload,
			};
		case GET_DELEGATED_EMAIL_IDS_FOR_DROPDOWN:
			let idMap = {};
			for (let id of payload.value) {
					idMap[id.DelegatedEmail] = { DelegatedEmail: id.DelegatedEmail };
			}
			let idList = Object.keys(idMap);			
			payload.value = idList.filter(delegateId => !(/\s/g.test(delegateId)));
			
			return {
				...state,
				delegatedEmailIDsDropdown: payload,
			};
		case FAILED_GET_DELEGATED_EMAIL_IDS_FOR_DROPDOWN:
			let map = {};
			map["@odata.context"] = "";
			map["value"] = [];
			return { ...state, delegatedEmailIDsDropdown: map };
		default:
			return state;
	}
};