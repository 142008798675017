import React, { memo } from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";

function Tooltip(props) {
  const {
    children,
    placement = "bottom",
    trigger = "hover",
    tooltipBody,
  } = props;
  return (
    <OverlayTrigger
      {...props}
      rootClose
      trigger={[trigger, "focus"]}
      placement={placement}
      key={"key-tooltip"}
      overlay={
        <Popover>
          <Popover.Body className="shadow-sm text-primary fs-sm fw-normal lh-sm">
            {tooltipBody}
          </Popover.Body>
        </Popover>
      }
    >
      {children}
    </OverlayTrigger>
  );
}

export default memo(Tooltip);
