import { REMOVE_ALL_BOOKMARKS_DATA, SET_BOOKMARKS_DATA, SET_BOOKMARKS_EXISTS_DATA } from "./actions";

const initialState = {
  bookmarksData: {
    total: 0,
    page: 1,
    bookmarks: []
  },
  bookmarksExistsData: [],
};

const bookmarksReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_BOOKMARKS_DATA:
      return { ...state, bookmarksData: payload };

    case REMOVE_ALL_BOOKMARKS_DATA:
      return initialState;

    case SET_BOOKMARKS_EXISTS_DATA:
      return { ...state, bookmarksExistsData: payload };

    default:
      return state;
  }
};

export default bookmarksReducer;
