import { hideLoading, showLoading } from "../../components/Loading/action";
import {
  addToBookmarksAPI,
  checkBookmarksExistsAPI,
  fetchBookmarksAPI,
  removeAllBookmarksAPI,
  removeFromBookmarksAPI,
} from "../../services/bookmarkServices";

export const SET_BOOKMARKS_DATA = "SET_BOOKMARKS_DATA";
export const REMOVE_ALL_BOOKMARKS_DATA = "REMOVE_ALL_BOOKMARKS_DATA";
export const SET_BOOKMARKS_EXISTS_DATA = "SET_BOOKMARKS_EXISTS_DATA";

export const fetchBookmarksList =
  (searchTerm, tokenPromise) => async (dispatch) => {
    const { perPage = 10, pageOffset = 0, recordCreatedBy } = searchTerm;
    dispatch(showLoading());
    try {
      const response = await fetchBookmarksAPI(
        { perPage, pageOffset, recordCreatedBy },
        tokenPromise
      );
      dispatch({ type: SET_BOOKMARKS_DATA, payload: response.data });
      return response.data;
    } catch (error) {
      console.info("error fetchBookmarksList =>", error);
      return error;
    } finally {
      dispatch(hideLoading());
    }
  };

export const addToBookmarks = (payload, tokenPromise) => async (dispatch) => {
  const { azureMerchantId, recordCreatedBy } = payload;
  dispatch(showLoading());
  try {
    const response = await addToBookmarksAPI(
      { azureMerchantId, recordCreatedBy },
      tokenPromise
    );
    // dispatch({ type: SET_BOOKMARKS_DATA, payload: response.data });
    return response.data;
  } catch (error) {
    console.info("error addToBookmarks =>", error);
    return error;
  } finally {
    dispatch(hideLoading());
  }
};

export const removeFromBookmarks =
  (payload, tokenPromise) => async (dispatch) => {
    const { azureMerchantId, recordCreatedBy } = payload;
    dispatch(showLoading());
    try {
      const response = await removeFromBookmarksAPI(
        { azureMerchantId, recordCreatedBy },
        tokenPromise
      );
      // dispatch({ type: SET_BOOKMARKS_DATA, payload: response.data });
      return response.data;
    } catch (error) {
      console.info("error removeFromBookmarks =>", error);
      return error;
    } finally {
      dispatch(hideLoading());
    }
  };

export const removeAllBookmarks =
  (recordCreatedBy, tokenPromise) => async (dispatch) => {
    dispatch(showLoading());
    try {
      const response = await removeAllBookmarksAPI(
        recordCreatedBy,
        tokenPromise
      );
      dispatch({ type: REMOVE_ALL_BOOKMARKS_DATA });
      return response.data;
    } catch (error) {
      console.info("error removeAllBookmarks =>", error);
      return error;
    } finally {
      dispatch(hideLoading());
    }
  };

export const checkBookmarksExists =
  (searchTerm, tokenPromise) => async (dispatch) => {
    const { recordCreatedBy, azureMerchantIds } = searchTerm;
    dispatch(showLoading());
    try {
      const response = await checkBookmarksExistsAPI(
        { recordCreatedBy, azureMerchantIds },
        tokenPromise
      );
      dispatch({ type: SET_BOOKMARKS_EXISTS_DATA, payload: response.data });
      return response.data;
    } catch (error) {
      console.info("error checkBookmarksExists =>", error);
      return error;
    } finally {
      dispatch(hideLoading());
    }
  };
