import { hideLoading, showLoading } from "../../components/Loading/action";
import { fetchLeadsAPI, getLeadsFilterString } from "../../services/leadServices";

export const SET_LEADS_DATA = "SET_LEADS_DATA";
export const SET_MERCHANT_LEADS_DATA = "SET_MERCHANT_LEADS_DATA";
export const SET_LEADS_SELECTED_FILTERS = "SET_LEADS_SELECTED_FILTERS";
export const SET_MERCHANT_LEADS_SELECTED_FILTERS = "SET_MERCHANT_LEADS_SELECTED_FILTERS";

export const fetchLeadList = (searchTerm, tokenPromise) => async (dispatch, getState) => {  
  const { leadsReducer : { leadsSelectedFilters }} = getState();
  let { perPage = 10, skip = 0, searchValue} = searchTerm;
  let searchString = searchValue?.replace(/\s+/g, " ").trim().split(" ").join(" | ") || "";
  dispatch(showLoading());
  try {
    const response = await fetchLeadsAPI({ searchValue: searchString, filterkeys: leadsSelectedFilters && getLeadsFilterString(leadsSelectedFilters), perPage, skip }, tokenPromise);
    dispatch({ type: SET_LEADS_DATA, payload: response.data });
  } catch (error) {
    console.info("error fetchLeadList =>", error);
    return error;
  } finally {
    dispatch(hideLoading());
  }
};

export const fetchMerchantLeadList = (searchTerm, tokenPromise) => async (dispatch, getState) => {
  const { leadsReducer : {merchantLeadsSelectedFilters}} = getState();
  
  let { perPage = 10, skip = 0, searchValue, merchantId } = searchTerm;  
  let searchString = searchValue?.replace(/\s+/g, " ").trim().split(" ").join(" | ") || "";

  dispatch(showLoading());
  try {
    let filterWithMerchant = merchantLeadsSelectedFilters ? `merchantId eq '${merchantId}' and ${getLeadsFilterString(merchantLeadsSelectedFilters)}` : `merchantId eq '${merchantId}'`; 
    const response = await fetchLeadsAPI({ searchValue:searchString, filterkeys:filterWithMerchant, perPage, skip, merchantId }, tokenPromise);
    dispatch({ type: SET_MERCHANT_LEADS_DATA, payload: response.data });
  } catch (error) {
    console.info("error fetchLeadList =>", error);
    return error;
  } finally {
    dispatch(hideLoading());
  }
};
