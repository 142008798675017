import React, { Suspense, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";

import Header from "./Header";
import FallbackLoader from "./FallbackLoader";
import { requestProfileData } from "../services/signInServices";

function Layout() {
  const isAuthenticated = useIsAuthenticated();
  const dispatch = useDispatch();
  const { instance, accounts } = useMsal();
  const profileReducerState = useSelector((state) => state.profileReducer);

  useEffect(() => {
    if (isAuthenticated && !profileReducerState.profile) {
      dispatch(requestProfileData(instance, accounts));
    }
  }, [isAuthenticated, profileReducerState]);

  return (
    <div className="">
        <Header />
        <Suspense fallback={<FallbackLoader />}>
          <Outlet />
        </Suspense>
    </div>
  );
}

export default Layout;
