import React, { Suspense } from "react";
import { ToastContainer } from "react-toastify";
import "./styles/_init.scss";
import "react-toastify/dist/ReactToastify.css";
import Router from "./router";
import FallbackLoader from "./Layouts/FallbackLoader";

function App() {
	return (
		<Suspense fallback={<FallbackLoader />}>
			<Router />
			<ToastContainer
				position="bottom-center"
				autoClose={5000}
				hideProgressBar
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
				theme="dark"
				className={"w-100"}
			/>
		</Suspense>
	);
}
export default App;
