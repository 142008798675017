export const LEADS_URL = '/leads';
export const OPPORTUNITIES_URL = '/v2/opp';
export const OFFERS_URL = "/v2/offers";
export const LEADS_API_URL = '/v2/leads';
export const FUNDED_DEALS_URL = "/v2/opp";
export const DOCUMENTS_URL = "/v2/docs";
export const BOOKMARKS_URL = "/bookmarks";

// export const GRAPH_MESSAGE_ME_URL = "/me/messages";
// export const GRAPH_USERS_URL = "/users";
