import axios from "../config/axiosInterceptor"
import { DOCUMENTS_URL,OPPORTUNITIES_URL } from "../utils/apiEndpoints";

export const fetchFundedDealsAPI = async (searchTerm, tokenPromise) => {
  const { skip, perPage, searchValue, filterKeys, orderby = '' } = searchTerm;  
  let params = {
    $count: true,
    $skip: skip,
    $top: perPage,
    highlight: "dealName,funders,funderType,salesRepOpenerName,salesRepCloserName",
    highlightPreTag: "<span class=bg-yellow-light>",
    highlightPostTag: "</span>",
  };

  if(orderby){ params = {...params, $orderby: `${orderby} desc`} }
  if (searchValue) params.search = searchValue;
  if (filterKeys) {
    params = {...params, $filter:filterKeys}
  }

  const accessToken = await tokenPromise;
  return await axios({
    url: OPPORTUNITIES_URL,
    method: "GET",
    params,
    headers: { Authorization: `Bearer ${accessToken}` },
  });
};

export const getFundedDealsFilterString = ({
  salesRepSelectOptions,
  funderSelectOptions,
  startDate,
  endDate,
  statusSelectedOption,
  stageSelectedOptions,
  isFundedDeals = false
}) => {
  let finalURL = "";
  let funderFilterString = "(";
  let salesRepFilterString = "";
  let statusFilterString = "";
  let stageFilterString = "";

  if (funderSelectOptions && funderSelectOptions.length > 0) {
    funderSelectOptions.forEach((element, index) => {
      if (index === 0) {
        funderFilterString += `funders eq '${element.value}'`;
      } else {
        funderFilterString += ` or funders eq '${element.value}'`;
      }
    });
  } else {
    funderFilterString = "";
  }

  if (funderFilterString) {
    funderFilterString += ")";
    salesRepFilterString += " and (";
  } else {
    salesRepFilterString = "(";
  }

  if (salesRepSelectOptions.length > 0) {
    salesRepSelectOptions.forEach((element, index) => {
      if (index === 0) {
        salesRepFilterString += `(salesRepOpenerName eq '${element.value}' or salesRepCloserName eq '${element.value}')`;
      }else{
        salesRepFilterString += ` or (salesRepOpenerName eq '${element.value}' or salesRepCloserName eq '${element.value}')`;
      }
    });
  } else{
    salesRepFilterString = ""
  }

  if(salesRepFilterString){
    salesRepFilterString += ")"
  }
  
  if (stageSelectedOptions && stageSelectedOptions.length > 0) {
    stageFilterString += "(";
    stageSelectedOptions.forEach((element, index) => {
      if (index === 0) {
        stageFilterString += `stage eq '${element.value}'`;
      } else {
        stageFilterString += ` or stage eq '${element.value}'`;
      }
    });
    stageFilterString += salesRepFilterString ? ") and " : ")";
  } else {
    stageFilterString = "";
  }

  
  if (statusSelectedOption && !!statusSelectedOption.length) {
    statusFilterString += "(";
    statusSelectedOption.forEach((option, index) => {
      if (index === 0) {
        statusFilterString += `status eq '${option.value}'`;
      } else {
        statusFilterString += `or status eq '${option.value}'`;
      }
    });
    statusFilterString +=
      salesRepFilterString || stageFilterString ? ") and " : ")";
  } else {
    statusFilterString = "";
  }

  let startDateString = "";
  let endDateString = "";
  let dateFilterString = "";

  if (startDate) {
    startDateString = `${isFundedDeals ? 'fundedDate' : 'submittedOn'} ge ${startDate}`;
  }

  if (endDate) {
    endDateString = `${isFundedDeals ? 'fundedDate' : 'submittedOn'} le ${endDate}`;
  }

  if(startDateString && endDateString){
    dateFilterString = `${startDateString} and ${endDateString}`;
  } else if(startDateString && !endDateString){
    dateFilterString = startDateString;
  } else if(endDateString && !startDateString){
    dateFilterString += `${endDateString}`;
  }

  // if (startDateString) {
  //   dateFilterString = startDateString;
  // }

  // if (dateFilterString) {
  //   dateFilterString += ` and ${endDateString}`;
  // } else {
  //   dateFilterString += `${endDateString}`;
  // }

  if (funderFilterString || salesRepFilterString || statusFilterString) {
    finalURL += `(${statusFilterString}${stageFilterString}${funderFilterString}${salesRepFilterString})`;
    if (dateFilterString) {
      finalURL += ` and (${dateFilterString})`;
    }
  } else {
    if (dateFilterString) {
      finalURL += `(${dateFilterString})`;
    }
  }

  return finalURL;
};

export const fetchExpandedDocsAPIs = async (searchTerm, tokenPromise) => {
  const { SourceReferenceId } = searchTerm
  const params = {
    $count: true,
    $orderby: "AzureUpdateDate desc",
    $filter: `SourceReferenceId eq 'OM_${SourceReferenceId}' or SourceReferenceId eq 'JF_${SourceReferenceId}'`
  }

  const accessToken = await tokenPromise;
  return await axios({
    url: DOCUMENTS_URL,
    method: "GET",
    params,
    headers: { Authorization: `Bearer ${accessToken}` },
  });
}